<template>
  <a-select
    :allow-clear="false"
    :filter-option="false"
    :label-in-value="true"
    mode="multiple"
    :options="options"
    :show-search="true"
    v-model="selected"
    @change="change"
    @popupScroll="popupScroll"
    @search="search"
  ></a-select>
</template>

<script>
import { getContactList } from '@/api/contact'

export default {
  name: 'ContactSelect',
  props: {
    value: {
      type: Array
    }
  },
  data () {
    return {
      form: {
        page: 1,
        pageSize: 20
      },
      selected: [],
      options: [],
      total: 0,
      timer: null
    }
  },
  mounted () {
    if (this.value) {
      this.value.forEach(item => {
        this.selected.push({
          label: item.name,
          key: item.id
        })
      })
    }
    this.fetch()
  },
  methods: {
    fetch () {
      getContactList(this.form).then(res => {
        const data = res.data
        this.total = data.total
        data.data.forEach(contact => {
          if (
            this.options.filter(option => option.value === contact.id)
              .length === 0
          ) {
            this.options.push({
              key: contact.id,
              label: contact.name
            })
          }
        })
      })
    },
    popupScroll (e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      if (scrollHeight - scrollTop === clientHeight) {
        if (this.total > this.form.page * this.form.pageSize) {
          this.form.page += 1
          this.fetch()
        }
      }
    },
    search (value) {
      this.form.name = value
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.options = []
        this.fetch()
      }, 500)
    },
    change (value) {
      this.$emit(
        'input',
        value.map(item => {
          return {
            id: item.key,
            name: item.label
          }
        })
      )
    }
  }
}
</script>
