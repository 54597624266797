<template>
  <a-icon :component="component"></a-icon>
</template>

<script>
export default {
  name: 'NetworkInterfaceIcon',
  data () {
    return {
      component: {
        template: `
          <svg viewBox="0 0 1024 1024" width="1em" height="1em">
            <path d="M704 490.666667H320c-12.8 0-21.333333 8.533333-21.333333 21.333333v170.666667c0 12.8 8.533333 21.333333 21.333333 21.333333h32v-74.666667c0-17.066667 14.933333-32 32-32s32 14.933333 32 32V704h64v-74.666667c0-17.066667 14.933333-32 32-32s32 14.933333 32 32V704h64v-74.666667c0-17.066667 14.933333-32 32-32s32 14.933333 32 32V704H704c12.8 0 21.333333-8.533333 21.333333-21.333333v-170.666667c0-12.8-8.533333-21.333333-21.333333-21.333333z" fill="currentColor"></path>
            <path d="M832 256h-102.4l-57.6-121.6c-6.4-17.066667-21.333333-27.733333-40.533333-27.733333H392.533333c-17.066667 0-34.133333 10.666667-40.533333 27.733333L294.4 256H192c-46.933333 0-85.333333 38.4-85.333333 85.333333v490.666667c0 46.933333 38.4 85.333333 85.333333 85.333333h640c46.933333 0 85.333333-38.4 85.333333-85.333333V341.333333c0-46.933333-38.4-85.333333-85.333333-85.333333z m-42.666667 469.333333c0 23.466667-19.2 42.666667-42.666666 42.666667H277.333333c-23.466667 0-42.666667-19.2-42.666666-42.666667V469.333333c0-23.466667 19.2-42.666667 42.666666-42.666666h469.333334c23.466667 0 42.666667 19.2 42.666666 42.666666v256z" fill="currentColor"></path>
          </svg>
        `
      }
    }
  }
}
</script>
