<template>
  <div class="network-page">
    <a-row :gutter="16">
      <a-col :span="8" style="margin-bottom: 16px">
        <div class="module-container" style="height: 348px">
          <header>整体情况</header>
          <div class="overview-count">
            <div>
              <div class="label">网卡总数</div>
              <div class="value">{{ dataSource.length }}</div>
            </div>
            <div>
              <div class="label">正常网卡数</div>
              <div class="value" style="color: #096dd9">{{ normalCount }}</div>
            </div>
            <div>
              <div class="label">告警网卡数</div>
              <div class="value" style="color: #faad14">{{ alertCount }}</div>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="16" style="margin-bottom: 16px">
        <div class="module-container" style="height: 348px">
          <header>告警详情</header>
          <alert-table :detail="detail"></alert-table>
        </div>
      </a-col>
      <a-col :span="24" style="margin-bottom: 16px">
        <div class="module-container" :style="{ minHeight: '280px' }">
          <header>
            网口状态
            <a-space
              :size="12"
              style="position: absolute; right: 18px; top: 8px; font-size: 14px"
            >
              <a
                @click="displayInterfaceTable = false"
                :style="{ color: !displayInterfaceTable ? '#096dd9' : 'gray' }"
                ><a-icon type="appstore"
              /></a>
              <a
                @click="displayInterfaceTable = true"
                :style="{ color: displayInterfaceTable ? '#096dd9' : 'gray' }"
                ><a-icon type="profile"
              /></a>
            </a-space>
          </header>
          <network-interface-card
            v-if="!displayInterfaceTable"
            :loading="loading"
            :data-source="dataSource"
            :selected="selectedInterface"
            @changeSelected="changeSelected"
          ></network-interface-card>

          <hardware-network-interface-table
            v-else
            :data-source="dataSource"
            :loading="loading"
            source-type="network_device"
            :source-id="detail.id"
          ></hardware-network-interface-table>
        </div>
      </a-col>
      <a-col
        :span="24"
        style="margin-bottom: 16px"
        v-if="selectedInterface && !displayInterfaceTable"
      >
        <div class="module-container" :style="{ height: '470px' }">
          <header>{{ selectedInterface.name }}</header>

          <a-space
            :size="8"
            style="
              margin-bottom: 24px;
              position: absolute;
              right: 20px;
              top: 18px;
            "
          >
            <a-checkbox v-model="compareYesterday">
              <question-icon
                title="环比"
                description="昨天同时段"
              ></question-icon>
            </a-checkbox>
            <a-checkbox v-model="compareLastWeek">
              <question-icon
                title="同比"
                description="上周同时段"
              ></question-icon>
            </a-checkbox>
            <datetime-range
              style="width: 300px"
              :allow-clear="false"
              :default-value="datetimeRange"
              @ok="
                v => {
                  datetimeRange = v
                }
              "
            ></datetime-range>
          </a-space>

          <network-interface-chart
            :compare-last-week="compareLastWeek"
            :compare-yesterday="compareYesterday"
            :datetime-range="datetimeRange"
            :history-func="historyFunc"
            name="interface-chart"
            :in-key="inKey"
            :out-key="outKey"
            :id="detail.id"
          ></network-interface-chart>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
import {
  getNetworkDeviceNetworkInterfaceList,
  getNetworkDeviceMonitorHistory,
  getNetworkDeviceMonitorItemList
} from '@/api/network-device'
import HardwareNetworkInterfaceTable from '@/components/table/HardwareNetworkInterfaceTable.vue'
import AlertTable from './modules/AlertTable.vue'
import NetworkInterfaceCard from '@/components/source-detail-page/NetworkInterfaceCard.vue'
import DatetimeRange from '@/components/DatetimeRange'
import QuestionIcon from '@/components/icon/QuestionIcon'
import NetworkInterfaceChart from '@/components/source-detail-page/NetworkInterfaceChart.vue'

export default {
  name: 'NetworkPage',
  components: {
    HardwareNetworkInterfaceTable,
    NetworkInterfaceCard,
    AlertTable,
    DatetimeRange,
    QuestionIcon,
    NetworkInterfaceChart
  },
  props: {
    detail: {
      type: Object
    }
  },
  data () {
    return {
      dataSource: [],
      normalCount: 0,
      alertCount: 0,
      loading: false,
      displayInterfaceTable: false,
      selectedInterface: undefined,
      compareYesterday: false,
      compareLastWeek: false,
      historyFunc: getNetworkDeviceMonitorHistory,
      datetimeRange: [moment().subtract(1, 'hour'), moment()],
      inKey: '',
      outKey: ''
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      this.normalCount = 0
      this.alertCount = 0
      getNetworkDeviceNetworkInterfaceList(this.detail.id)
        .then(res => {
          const data = res.data
          if (data && data.data.length) {
            data.data.forEach(item => {
              const obj = {
                id: item.id,
                name: item.name,
                index: item.index,
                interface_type: item.interface_type,
                status: item.status,
                speed: 0,
                net_if_in: 0,
                net_if_out: 0
              }
              this.getKeys(item.index).forEach(keyObj => {
                getNetworkDeviceMonitorItemList(this.detail.id, {
                  key: keyObj.key
                }).then(res1 => {
                  obj[keyObj.dataIndex] = res1.data.data.length
                    ? res1.data.data[0].value
                    : 0
                })
              })
              if (obj.status === 'normal') this.normalCount++
              if (obj.status === 'alert') this.alertCount++

              this.dataSource.push(obj)
            })
            this.changeSelected(this.dataSource[0])
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getKeys (index) {
      return [
        { key: `net.if.speed[ifHighSpeed.${index}]`, dataIndex: 'speed' },
        { key: `net.if.in[ifHCInOctets.${index}]`, dataIndex: 'net_if_in' },
        { key: `net.if.out[ifHCOutOctets.${index}]`, dataIndex: 'net_if_out' }
      ]
    },
    changeSelected (data) {
      this.selectedInterface = data
      this.inKey = `ifHCInOctets.${data.index}`
      this.outKey = `ifHCOutOctets.${data.index}`
    }
  }
}
</script>

<style lang="less">
.network-page {
  .module-container {
    position: relative;
    background: #fff;
    border-radius: 6px;
    padding: 16px 16px 8px 16px;
    margin-right: 1px;
    overflow: hidden;
    border: 1px solid #e5e5e5;

    header {
      font-size: 16px;
      font-weight: 500;
      color: #096dd9;
      line-height: 22px;
    }

    .overview-count {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      row-gap: 16px;
      column-gap: 16px;
      height: 270px;

      & > div {
        border-radius: 6px;
        background: powderblue;
        text-align: center;
        padding-top: 8px;
        line-height: 24px;
        background: #e6f0fb;
        border-top: 2px solid #0a6dd9;

        .label {
          font-size: 12px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.5);
        }

        .value {
          font-size: 22px;
          color: rgba(0, 0, 0, 0.75);
          margin-top: 2px;
        }
        .red {
          color: rgb(255, 212, 212);
        }
      }
    }
  }
}
</style>
