<template>
    <div class="network-interface-card">
      <a-spin :spinning="loading">
        <div class="status-info" v-if="sourceType !== 'os'">
          <div class="normal" :style="{color: filterStatus === 'normal' ? '#096dd9' : ''}" @click="filterData('normal')">正常</div>
          <div class="abnormal" :style="{color: filterStatus === 'abnormal' ? '#096dd9' : ''}" @click="filterData('abnormal')">异常</div>
          <div class="unknown" :style="{color: filterStatus === 'unknown' ? '#096dd9' : ''}" @click="filterData('unknown')">未知</div>
        </div>
        <a-carousel v-if="dataList.length" effect="fade" ref="interfaceCarousel">
          <div v-for="(arr, index) in items" :key="index">
            <div
              style="
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-template-rows: repeat(auto-fill, 48px);
                align-items: center;
              "
            >
              <div v-for="i in arr" :key="i.name">
                <content-tooltip overlay-class-name="interface-tooltip">
                  <div slot="tooltipTitle">
                    <header
                      style="
                        margin-bottom: 6px;
                        color: #096dd9;
                        font-weight: 500;
                        font-size: 13px;
                      "
                    >
                      {{ i.name }}
                    </header>
                    <a-descriptions :column="1">
                      <a-descriptions-item label="入流量">
                        {{ i.net_if_in | human }}
                      </a-descriptions-item>
                      <a-descriptions-item label="出流量">
                        {{ i.net_if_out | human }}
                      </a-descriptions-item>
                      <a-descriptions-item label="速率">
                        {{ i.speed | human }}
                      </a-descriptions-item>
                    </a-descriptions>
                  </div>
                  <div
                    slot="tooltipBody"
                    @click="$emit('changeSelected', i)"
                    class="network-interface-item"
                  >
                    <div
                      :style="{
                        width: '32px',
                        height: '32px',
                        borderRadius: '4px',
                        textAlign: 'center',
                        border: `1px solid ${
                          (i.status === 'normal' || !i.status)
                            ? '#096dd9'
                            : StatusColor(i.status)
                        }`,
                        cursor: 'pointer'
                      }"
                    >
                      <network-interface-icon
                        :style="{
                          fontSize: '26px',
                          marginTop: '3px',
                          color:
                            (i.status === 'normal' || !i.status)
                              ? '#096dd9'
                              : StatusColor(i.status),
                          cursor: 'pointer'
                        }"
                      ></network-interface-icon>
                    </div>
                    <div
                      :style="{
                        marginLeft: '4px',
                        color:
                          selected && selected.name === i.name
                            ? '#096dd9'
                            : 'rgba(0,0,0,.85)',
                        cursor: 'pointer',
                        transition: 'all .4s'
                      }"
                    >
                      {{ i.name }}
                    </div>
                  </div>
                </content-tooltip>
              </div>
            </div>
          </div>
        </a-carousel>
        <div v-else-if="!dataList.length" style="height: 210px; position: relative; overflow: hidden;">
          <empty-component
            :imgUrl="require('@/assets/images/info_empty.png')"
            :body-style="{
              height: '120px',
              marginTop: '28px'
            }"
            :description-style="{
              marginTop: '-8px',
              marginRight: '8px'
            }"
            description="暂无数据"
          ></empty-component>
        </div>
      </a-spin>
    </div>
</template>

<script>
import { StatusColor, humanizeValue } from '@/utils'
import ContentTooltip from '@/components/ContentTooltip'
import NetworkInterfaceIcon from '@/components/icon/NetworkInterfaceIcon'

export default {
  name: 'NetworkInterfaceCard',
  components: {
    EmptyComponent: () => import('@/components/EmptyComponent.vue'),
    NetworkInterfaceIcon,
    ContentTooltip
  },
  props: {
    dataSource: {
      type: Array,
      required: true
    },
    selected: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    },
    sourceType: {
      type: String
    }
  },
  computed: {
    dataList () {
      let arr = []
      if (this.filterStatus !== null) {
        this.dataSource.forEach(item => {
          if (item.status === this.filterStatus) arr.push(item)
        })
      } else {
        arr = this.dataSource
      }
      return arr
    },
    items () {
      const total = this.dataList.length
      const arr = []
      for (let i = 0; i < Math.ceil(total / 20); i++) {
        arr.push(this.dataList.slice(i * 20, (i + 1) * 20))
      }
      return arr
    }
  },
  data () {
    return {
      name: undefined,
      filterStatus: null
    }
  },
  methods: {
    StatusColor,
    filterData (status) {
      if (this.dataSource && this.dataSource.length) {
        if (this.filterStatus !== null) {
          if (this.filterStatus === status) this.filterStatus = null
          else this.filterStatus = status
        } else {
          this.filterStatus = status
        }
      }
    }
  },
  filters: {
    human (value) {
      if (value * 1 >= 0) return humanizeValue(value * 1)
      return 0
    }
  }
}
</script>

<style lang="less">
.interface-tooltip {
  .ant-tooltip-arrow::before {
    background: #fff;
  }
  .ant-tooltip-inner {
    background: #fff;

    .ant-descriptions {
      width: 146px;
      .ant-descriptions-row > td {
        padding-bottom: 8px;
        .ant-descriptions-item-label {
          font-size: 12px;
          color: #343434;
        }
        .ant-descriptions-item-content {
          font-size: 12px;
          color: #343434;
        }
      }
    }
  }
}
.network-interface-card {
  position: relative;
  height: 226px;
  .status-info {
    text-align: center;
    margin-top: -24px;
    margin-bottom: 12px;
    & > div {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      width: 60px;
      display: inline-block;
      text-align: center;
      z-index: 1000;
      cursor: pointer;

      &::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 2px;
        display: inline-block;
      }
    }

    & > div.normal::before {
      background: #096dd9;
    }
    & > div.abnormal::before {
      background: rgb(239, 79, 79);
    }
    & > div.alert::before {
      background: rgb(237, 154, 20);
    }
    & > div.unknown::before {
      background: rgb(171, 171, 171);
    }
  }
  .ant-carousel .slick-dots-bottom {
    bottom: -8px;

    li button {
      background: #b5b5b5;
    }

    li.slick-active button {
      background: #096dd9;
    }
  }

  .network-interface-item {
    height: 48px;
    display: flex;
    align-items: center;
    cursor: 'pointer';
    overflow: hidden;

    & > div:nth-of-type(2) {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
</style>
