<template>
  <div>
    <div
      style="
        height: 32px;
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 32px;
      "
    >
      <a-space size="middle" style="float: right">
        <a-input-search
          placeholder="请输入关键词"
          @search="search"
        ></a-input-search>
      </a-space>
    </div>

    <a-table
      class="custom-table scroll-hidden-table"
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      :scroll="{y: 700}"
      row-key="id"
      style="margin-bottom: 16px"
    >
      <template slot="name" slot-scope="text, record">
        <a-tooltip placement="topLeft" :title="text">
          <a @click="$refs.chartModal.show(record)">{{ text }}</a>
        </a-tooltip>
      </template>
      <template slot="speed" slot-scope="text">
        {{ text | human }}bps
      </template>
      <template slot="net_if_in" slot-scope="text">
        {{ text | human }}bps
      </template>
      <template slot="net_if_out" slot-scope="text">
        {{ text | human }}bps
      </template>
      <template slot="status" slot-scope="text">
        <source-status-tag :status="text"></source-status-tag>
      </template>
    </a-table>

    <network-interface-chart-modal
      ref="chartModal"
      :source-type="sourceType"
      :source-id="sourceId"
    ></network-interface-chart-modal>
  </div>
</template>

<script>
// import ReloadButton from '@/components/button/ReloadButton.vue'
import NetworkInterfaceChartModal from '@/components/modal/NetworkInterfaceChartModal/index.vue'
import SourceStatusTag from '@/components/tag/SourceStatusTag.vue'
import { humanizeValue } from '@/utils'
import { sourceStatuses } from '@/utils/const'

export default {
  name: 'HardwareNetworkInterfaceTable',
  components: {
    NetworkInterfaceChartModal,
    // ReloadButton,
    SourceStatusTag
  },
  props: {
    dataSource: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    sourceType: {
      type: String
    },
    sourceId: {
      type: String,
      required: true
    }
  },
  computed: {
    columns () {
      const obj = [
        {
          dataIndex: 'name',
          title: '名称',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          },
          filteredValue: this.searchText,
          onFilter: (value, record) => {
            return record.name.toString().toLowerCase().includes(value.toLowerCase())
          }
        },
        {
          dataIndex: 'interface_type',
          title: '类型',
          width: 160
        },
        {
          dataIndex: 'speed',
          title: '速率',
          width: 100,
          scopedSlots: {
            customRender: 'speed'
          }
        },
        {
          dataIndex: 'net_if_in',
          title: '入流量',
          width: 120,
          scopedSlots: {
            customRender: 'net_if_in'
          },
          defaultSortOrder: 'descend',
          sorter: (a, b) => (a.net_if_in * 1) - (b.net_if_in * 1)
        },
        {
          dataIndex: 'net_if_out',
          title: '出流量',
          width: 120,
          scopedSlots: {
            customRender: 'net_if_out'
          },
          defaultSortOrder: 'descend',
          sorter: (a, b) => (a.net_if_out * 1) - (b.net_if_out * 1)
        }
      ]
      if (this.sourceType !== 'os') {
        obj.push({
          dataIndex: 'status',
          title: '状态',
          align: 'center',
          width: 160,
          scopedSlots: {
            customRender: 'status'
          },
          filters: sourceStatuses.map(value => {
            return {
              text: this.$t(`source_status.${value}`),
              value
            }
          }),
          filterMultiple: false,
          onFilter: (value, record) => {
            return record.status === value
          }
        })
      }
      return obj
    }
  },
  data () {
    return {
      searchText: []
    }
  },
  methods: {
    search (text) {
      this.searchText = [text]
    }
  },
  filters: {
    human (value) {
      return humanizeValue(value)
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar) {
  display: none !important;
  border: none !important;
}
</style>
