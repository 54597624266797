<template>
  <a-button
    icon="sync"
    size="small"
    type="link"
    @click="e => $emit('click', e)"
    style="color: #1890ff"
  >
    同步
  </a-button>
</template>

<script>
export default {
  name: 'SyncButton'
}
</script>
