<template>
  <a-drawer
    class="detail-drawer"
    :closable="false"
    :body-style="{ padding: '8px 16px', overflow: 'hidden' }"
    :destroy-on-close="true"
    :visible="visible"
    :width="840"
    @close="handleClose"
  >
    <a-tabs type="card">
      <a-tab-pane key="overview" tab="总览">
        <div style="flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
          <a-alert
            v-if="detail.block"
            message="告警已屏蔽"
            :description="`屏蔽策略: ${detail.block.strategy.name}`"
            :show-icon="true"
            type="warning"
            style="margin-bottom: 16px;"
          ></a-alert>

          <div class="small-module-title">
            <a-icon type="profile"></a-icon>
            <span> 基本信息</span>
          </div>
          <div style="padding: 0 32px">
            <a-descriptions>
              <a-descriptions-item label="严重性">
                <alert-severity-tag :severity="detail.severity"></alert-severity-tag>
              </a-descriptions-item>
              <a-descriptions-item label="资源类型">
                {{ $t(`source_type.${detail.source_type}`) }}
              </a-descriptions-item>
              <a-descriptions-item label="资源名称">
                {{ detail.source?.name }}
              </a-descriptions-item>
              <a-descriptions-item label="触发时间">
                {{ detail.triggered_at }}
              </a-descriptions-item>
              <a-descriptions-item label="恢复时间">
                {{ detail.recovered_at }}
              </a-descriptions-item>
              <a-descriptions-item label="持续时间">
                {{ duration }}
              </a-descriptions-item>
              <a-descriptions-item label="状态">
                <boolean-tag
                  :value="detail.recovered"
                  false-name="未恢复"
                  true-name="已恢复"
                ></boolean-tag>
              </a-descriptions-item>
            </a-descriptions>
          </div>
          <div class="small-module-title" style="margin-top: 8px">
            <a-icon type="clock-circle"></a-icon>
            <span> 快照</span>
          </div>
          <div style="padding: 0 16px">
            <a-alert
              message="获取告警相关最近一小时的监控数据。"
              :show-icon="true"
              style="margin-bottom: 16px;"
            ></a-alert>
            <request-type-radio class="widthAuto" @change=" v => requestType = v" :options="requestTypeData" style="margin-bottom: 16px;"></request-type-radio>
            <template v-if="snapshot">
              <snapshot-chart v-if="displayType === 'chart'" :snapshot="snapshot"></snapshot-chart>
              <snapshot-table v-else :snapshot="snapshot"></snapshot-table>
            </template>
            <a-empty v-else></a-empty>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="notification" tab="告警通知">
        <div style="flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
          <notification-table :data-source="notifications"></notification-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script>
import {
  getAlert,
  getAlertNotificationList,
  getAlertSnapshotList
} from '@/api/alert'
import RequestTypeRadio from '@/components/radio/RequestTypeRadio'
import AlertSeverityTag from '@/components/tag/AlertSeverityTag'
import BooleanTag from '@/components/tag/BooleanTag'
import { humanAlertDuration } from '@/utils'
import NotificationTable from './modules/NotificationTable'
import SnapshotChart from './modules/SnapshotChart.vue'
import SnapshotTable from './modules/SnapshotTable.vue'

export default {
  name: 'AlertDrawer',
  components: {
    AlertSeverityTag,
    BooleanTag,
    NotificationTable,
    RequestTypeRadio,
    SnapshotChart,
    SnapshotTable
  },
  data () {
    return {
      visible: false,
      detail: {
        id: '',
        name: '',
        severity: 'unknown',
        triggered_at: '',
        recovered_at: '',
        recovered: false,
        source_type: 'unknown',
        source: {
          id: '',
          name: ''
        },
        block: undefined
      },
      snapshots: [],
      notifications: [],
      requestTypeData: [],
      requestType: '',
      snapshot: undefined,
      displayType: 'chart'
    }
  },
  computed: {
    duration () {
      return humanAlertDuration(
        this.detail.triggered_at,
        this.detail.recovered_at
      )
    }
  },
  methods: {
    show (id) {
      const p1 = getAlert(id)
        .then(res => {
          this.detail = res.data
        })
      const p2 = getAlertSnapshotList(id).then(res => {
        this.snapshots = res.data.data
        const arr = []
        this.snapshots.forEach(item => {
          const { name } = item
          arr.push({ label: name, value: name })
        })
        this.requestTypeData = [...arr]
        this.requestType = this.requestTypeData.length ? this.requestTypeData[0].value : ''
      })
      const p3 = getAlertNotificationList(id).then(res => {
        this.notifications = res.data.data
      })
      Promise.all([p1, p2, p3]).then(() => {
        this.visible = true
      })
    },
    handleClose () {
      this.snapshot = undefined
      this.requestType = ''
      this.requestTypeData = []
      this.visible = false
    }
  },
  watch: {
    requestType (v) {
      if (v) {
        this.snapshot = this.snapshots.find(item => v === item.name)
        if (
          this.snapshot.value_type === 'numeric_unsigned' ||
          this.snapshot.value_type === 'numeric_float'
        ) this.displayType = 'chart'
        else {
          this.displayType = 'table'
        }
      }
    }
  }
}
</script>

<style lang="less">
.detail-drawer {
  .small-module-title{
    height: 35px;
    background: #E4F1FF;
    border-radius: 8px;
    color: #096DD9;
    padding: 0 16px;
    margin-bottom: 16px;

    i {
      font-size: 16px;
      height: 20px;
      vertical-align: sub;
    }
    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 37px;
      vertical-align: middle;
    }
  }

  .ant-descriptions-item-label {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(0,0,0,0.5);
    line-height: 17px;
  }

  .ant-descriptions-item-content {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(0,0,0,0.85);
    line-height: 17px;
  }

  .widthAuto  label{
    width: auto !important;
  }
}
</style>
