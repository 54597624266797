<template>
  <div class="hardware-page">
    <a-row :gutter="16">
      <a-col :span="12" style="margin-bottom: 16px;">
        <div class="module-container bg-module-container" style="height: 270px;">
          <hardware-resource-card :data-source="psus" :data-count="psuCount" type="psu" v-if="psus !== undefined">
            <span slot="titleText">电源</span>
            <monitor-icon slot="itemIcon" icon="power" style="font-size: 24px;"></monitor-icon>
          </hardware-resource-card>
        </div>
      </a-col>
      <a-col :span="12" style="margin-bottom: 16px;">
        <div class="module-container  bg-module-container" style="height: 270px;">
          <hardware-resource-card :data-source="fans" :data-count="fanCount" type="fan" v-if="fans !== undefined">
            <span slot="titleText">风扇</span>
            <monitor-icon slot="itemIcon" icon="fan" style="font-size: 24px;"></monitor-icon>
          </hardware-resource-card>
        </div>
      </a-col>
      <a-col :span="12" style="margin-bottom: 16px;">
        <div class="module-container  bg-module-container" style="height: 270px;">
          <hardware-resource-card :data-source="temps" :data-count="tempCount" type="temp" v-if="temps !== undefined">
            <span slot="titleText">温度</span>
            <monitor-icon slot="itemIcon" icon="temperature" style="font-size: 24px;"></monitor-icon>
          </hardware-resource-card>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { getNetworkDevicePSUList, getNetworkDeviceFanList, getNetworkDeviceTemperatureList, getNetworkDeviceMonitorItemList } from '@/api/network-device'
import HardwareResourceCard from '@/components/source-detail-page/HardwareResourceCard'
import MonitorIcon from '@/components/icon/MonitorIcon'

export default {
  name: 'HardwarePage',
  props: {
    detail: {
      type: Object
    }
  },
  components: {
    HardwareResourceCard,
    MonitorIcon
  },
  data () {
    return {
      pageData: [
        {
          key: 'power',
          name: '电源',
          percent: 100
        },
        {
          key: 'fan',
          name: '风扇',
          percent: 100
        },
        {
          key: 'memory',
          name: '内存',
          percent: 60
        },
        {
          key: 'cpu',
          name: 'CPU',
          percent: 98
        },
        {
          key: 'temperature',
          name: '温度',
          percent: 30
        }
      ],
      fans: [],
      fanCount: {
        normal: 0,
        abnormal: 0
      },
      psus: [],
      psuCount: {
        normal: 0,
        abnormal: 0
      },
      temps: [],
      tempCount: {
        normal: 0,
        abnormal: 0
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.psus = []
      this.psuCount = {
        normal: 0,
        abnormal: 0
      }
      this.fans = []
      this.fanCount = {
        normal: 0,
        abnormal: 0
      }
      this.temps = []
      this.tempCount = {
        normal: 0,
        abnormal: 0
      }
      getNetworkDevicePSUList(this.detail.id).then(res => {
        const data = res.data
        if (data && data.data.length) {
          data.data.forEach(item => {
            const obj = {
              id: item.id,
              name: item.name,
              index: item.index,
              status: item.status,
              value: 0,
              label: '功率',
              unit: item.unit ? item.unit : 'W',
              power: 0,
              updated_at: item.updated_at,
              created_at: item.created_at
            }
            const key = this.getKeys(item.index, 'psu')
            getNetworkDeviceMonitorItemList(this.detail.id, { key }).then(res => {
              obj.value = res.data && res.data.data.length
                ? res.data.data[0].value
                : '-'
              obj.power = obj.value
            })
            if (item.status === 'normal') this.psuCount.normal++
            else this.psuCount.abnormal++
            this.psus.push(obj)
          })
        } else this.psus = []
      })
      getNetworkDeviceFanList(this.detail.id).then(res => {
        const data = res.data
        if (data && data.data.length) {
          data.data.forEach(item => {
            const obj = {
              id: item.id,
              name: item.name,
              index: item.index,
              status: item.status,
              value: '-',
              label: '转速',
              unit: item.unit ? item.unit : 'rpm',
              speed: '-',
              updated_at: item.updated_at,
              created_at: item.created_at
            }
            const key = this.getKeys(item.index, 'fan')
            getNetworkDeviceMonitorItemList(this.detail.id, { key }).then(res => {
              obj.value = res.data && res.data.data.length
                ? res.data.data[0].value
                : '-'
              obj.speed = obj.value
            })
            if (item.status === 'normal') this.fanCount.normal++
            else this.fanCount.abnormal++
            this.fans.push(obj)
          })
        } else this.fans = []
      })
      getNetworkDeviceTemperatureList(this.detail.id).then(res => {
        const data = res.data
        if (data && data.data.length) {
          data.data.forEach(item => {
            const obj = {
              id: item.id,
              name: item.name,
              index: item.index,
              status: item.status,
              value: '-',
              label: '温度',
              unit: item.unit ? item.unit : '°C',
              updated_at: item.updated_at,
              created_at: item.created_at
            }
            const key = this.getKeys(item.index, 'temp')
            getNetworkDeviceMonitorItemList(this.detail.id, { key }).then(res => {
              obj.value = res.data && res.data.data.length
                ? res.data.data[0].value
                : '-'
            })
            if (item.status === 'normal') this.tempCount.normal++
            else this.tempCount.abnormal++
            this.temps.push(obj)
          })
        } else this.temps = []
      })
    },
    getKeys (index, type) {
      if (type === 'psu') return `sensor.psu.power[${index}]`
      if (type === 'fan') return `sensor.fan.speed[${index}]`
      if (type === 'temp') return `sensor.temp.value[${index}]`
    }
  }
}
</script>

<style lang="less">
.hardware-page {
  .module-container {
    position: relative;
    background: #fff;
    border-radius: 6px;
    padding: 16px 16px 8px 16px;
    overflow: hidden;
    border: 1px solid #e5e5e5;
    margin-right: 1px;

    header {
      font-size: 16px;
      font-weight: 500;
      color: #096dd9;
      line-height: 22px;
    }
  }
  .bg-module-container {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0;
      width: 41.67%;
      border-right: 1px solid #f6fbfe;
      background: #f6fbfe;
    }
  }
}
</style>
