<template>
  <div>
    <list-layout
      :can-operate="canOperate"
      :column-default-selected="columnDefaultSelected"
      :columns-all="columnsAll"
      :default-selected="tableDefaultSelectData"
      :export-func="exportFunc"
      :get-table-data-func="getTableDataFunc"
      :group-tree-func="groupTreeFunc"
      :in-modal="inModal"
      :refresh="updateTableFlag"
      :route-params="$route.params"
      :table-operation-width="tableOperationWidth"
      :tools-list="toolsList"
      @tableSelectData="getSelectData"
      :apiPermission="groupApiPermission"
      :can-drag-column-width="true"
    >
      <template #leftTool>
        <create-button v-permission="{action: 'service.site.create', effect: 'disabled'}" @click="$refs.inputDrawer.show()">
        </create-button>
      </template>
      <!-- 表格自定义列 -->
      <template #name="{ slotProps }">
        <a-tag v-if="slotProps.record.main" color="green">主</a-tag>
        <a-tooltip placement="topLeft" :title="slotProps.text">
          <a @click="$refs.detailDrawer.show(slotProps.record.id)" v-if="link">
            {{ slotProps.text }}
          </a>
          <span v-else>{{ slotProps.text }}</span>
        </a-tooltip>
      </template>
      <template #url="{ slotProps }">
        <a :href="slotProps.text" target="_blank">{{ slotProps.text }}</a>
      </template>
      <template #protocol="{ slotProps }">
        <a-tag color="blue">{{ slotProps.text.toUpperCase() }}</a-tag>
      </template>
      <template #delay="{ slotProps }">
        {{ `${slotProps.text} 秒` }}
      </template>
      <template #timeout="{ slotProps }">
        {{ `${slotProps.text} 秒` }}
      </template>
      <template #statusTitle>
        <status-question-icon></status-question-icon>
      </template>
      <template #status="{ slotProps }">
        <source-status-tag :status="slotProps.text"></source-status-tag>
      </template>
      <template #operation="{ slotProps }">
        <sync-button @click="sync(slotProps.record.id)"></sync-button>
        <edit-button
        v-permission="{action: 'service.site.update', effect: 'disabled'}"
          @click="$refs.inputDrawer.show(slotProps.record.id)"
        ></edit-button>
        <delete-button v-permission="{action: 'service.site.delete', effect: 'disabled'}" @confirm="confirm(slotProps.record.id)"></delete-button>
      </template>
    </list-layout>

    <site-drawer ref="detailDrawer"></site-drawer>
    <input-drawer ref="inputDrawer" @ok="confirm"></input-drawer>
  </div>
</template>

<script>
import {
  createSiteGroup,
  deleteSite,
  deleteSiteGroup,
  exportSite,
  getSiteGroup,
  getSiteGroupList,
  getSiteList,
  syncSite,
  updateSiteGroup
} from '@/api/site'
import ListLayout from '@/components/ListLayout'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import CreateButton from '@/components/button/CreateButton'
import SyncButton from '@/components/button/SyncButton'
import StatusQuestionIcon from '@/components/icon/StatusQuestionIcon'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
import { sourceStatuses, siteProtocols } from '@/utils/const'
import { hasPermission } from '@/utils'

export default {
  name: 'SiteTable',
  components: {
    SourceStatusTag,
    DeleteButton,
    EditButton,
    InputDrawer: () => import('./modules/InputDrawer.vue'),
    ListLayout,
    SiteDrawer: () => import('@/components/drawer/SiteDrawer.vue'),
    StatusQuestionIcon,
    SyncButton,
    CreateButton
  },
  props: {
    defaultSelected: {
      type: Array
    },
    toolsList: {
      type: Array,
      default: () => []
    },
    tableOperationWidth: {
      type: Number,
      default: 240
    },
    link: {
      type: Boolean,
      default: true
    },
    refresh: {
      type: Boolean
    },
    canOperate: {
      type: Boolean,
      default: true
    },
    inModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      getTableDataFunc: getSiteList,
      exportFunc: exportSite,
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'url',
          title: 'URL',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'url'
          }
        },
        {
          dataIndex: 'protocol',
          title: '协议类型',
          width: 105,
          scopedSlots: {
            customRender: 'protocol'
          },
          filters: siteProtocols.map(value => {
            return {
              text: value.toUpperCase(),
              value
            }
          }),
          filterMultiple: false
        },
        {
          dataIndex: 'delay',
          title: '监控频率',
          align: 'center',
          width: 100,
          scopedSlots: {
            customRender: 'delay'
          }
        },
        {
          dataIndex: 'retries',
          title: '重试次数',
          align: 'center',
          width: 100
        },
        {
          dataIndex: 'timeout',
          title: '请求超时',
          align: 'center',
          width: 100,
          scopedSlots: {
            customRender: 'timeout'
          }
        },
        {
          dataIndex: 'required',
          title: '响应内容',
          width: 180,
          ellipsis: true,
          scopedSlots: {
            customRender: 'required'
          }
        },
        {
          dataIndex: 'status',
          width: 100,
          slots: {
            title: 'statusTitle',
            name: '状态'
          },
          scopedSlots: {
            customRender: 'status'
          },
          filters: sourceStatuses.map(value => {
            return {
              text: this.$t(`source_status.${value}`),
              value
            }
          }),
          filterMultiple: false
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        }
      ],
      columnDefaultSelected: [
        'name',
        'url',
        'protocol',
        'delay',
        'retries',
        'timeout',
        'status'
      ],
      updateTableFlag: this.refresh,
      tableSelectData: [],
      tableDefaultSelectData: [],
      groupApiPermission: {
        create: 'service.site_group.create',
        edit: 'service.site_group.update',
        delete: 'service.site_group.delete'
      }
    }
  },
  computed: {
    groupTreeFunc () {
      if (hasPermission(['service.site_group.view'])) {
        return {
          createFunc: createSiteGroup,
          deleteFunc: deleteSiteGroup,
          getFunc: getSiteGroup,
          getListFunc: getSiteGroupList,
          updateFunc: updateSiteGroup
        }
      } else return null
    }
  },
  mounted () {
    if (this.defaultSelected) this.tableDefaultSelectData = this.defaultSelected
  },
  methods: {
    getSelectData (data) {
      this.tableSelectData = data
      this.$emit('selectedData', data)
    },
    sync (id) {
      syncSite({ id }).then(res => {
        this.$message.success(res.message)
      })
    },
    async confirm (id = '') {
      if (id) {
        await deleteSite(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(this.tableSelectData.findIndex(item => item.key === id), 1)
        this.tableDefaultSelectData = this.tableSelectData
        this.$emit('selectedData', this.tableSelectData)
      }
      this.updateTableFlag = !this.updateTableFlag
    }
  },
  watch: {
    refresh (v) {
      this.updateTableFlag = v
    }
  }
}
</script>
