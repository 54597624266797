import request from '@/utils/request'

const urlPrefix = '/alerts/notification-strategies'

export function getAlertNotificationStrategy (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateAlertNotificationStrategy (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteAlertNotificationStrategy (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getAlertNotificationStrategyList (params) {
  return request.get(urlPrefix, { params })
}

export function createAlertNotificationStrategy (data) {
  return request.post(urlPrefix, data)
}
