<template>
  <div>
    <list-layout
      sourceType="middleware"
      :can-operate="canOperate"
      :column-default-selected="columnDefaultSelected"
      :columns-all="columnsAll"
      :default-selected="tableDefaultSelectData"
      :export-func="exportFunc"
      :get-table-data-func="getTableDataFunc"
      :group-tree-func="groupTreeFunc"
      :in-modal="inModal"
      :refresh="updateTableFlag"
      :customer-params="params"
      :table-operation-width="tableOperationWidth"
      :search-type-options="[
        { label: '名称', value: 'name' },
        { label: 'IP 地址', value: 'monitor_address' },
        { label: '群组名称', value: 'group_id' }
      ]"
      :tools-list="toolsList"
      @tableSelectData="getSelectData"
      :apiPermission="groupApiPermission"
      :can-drag-column-width="true"
    >
      <template #leftTool v-if="!fetchParams.discovered">
        <create-button v-permission="{action: 'base.middleware.create', effect: 'disabled'}" @click="$refs.inputDrawer.show()">
        </create-button>
      </template>
      <!-- 表格自定义列 -->
      <template #name="{ slotProps }">
        <a-tooltip v-if="slotProps.record.discovered" title="自动发现" style="margin-right: 4px">
          <a-icon theme="filled" type="info-circle" style="color: #1890ff"></a-icon>
        </a-tooltip>
        <a-tooltip placement="topLeft" :title="slotProps.text">
          <a
            v-if="!slotProps.record.discovered && link"
            @click="$refs.middlewareDrawer.show(slotProps.record.id)"
          >
            {{ slotProps.text }}
          </a>
          <span v-else>{{ slotProps.text }}</span>
        </a-tooltip>
      </template>
      <template #groups="{ slotProps }">
        <a-tooltip v-if="slotProps.text && slotProps.text.length">
          <a-space slot="title" :size="8">
            <div v-for="item in slotProps.text" :key="item.id">{{item.name}}</div>
          </a-space>
          <a-tag v-for="item in slotProps.text" :key="item.id">{{ item.name }}</a-tag>
        </a-tooltip>
        <span v-else>-</span>
      </template>
      <template #middlewareType="{ slotProps }">
        {{ $t(`middleware_type.${slotProps.text}`) }}
      </template>
      <template #os="{ slotProps }">
        <a-tooltip placement="topLeft" :title="slotProps.text.name">
          <a @click="$refs.osDrawer.show(slotProps.text.id)" v-if="link">
            {{ slotProps.text.name }}
          </a>
          <span v-else>{{ slotProps.text.name }}</span>
        </a-tooltip>
      </template>
      <template #statusTitle>
        <status-question-icon></status-question-icon>
      </template>
      <template #status="{ slotProps }">
        <source-status-tag :status="slotProps.text"></source-status-tag>
      </template>
      <template #operation="{ slotProps }">
        <template v-if="!slotProps.record.discovered">
          <sync-button @click="sync(slotProps.record.id)"></sync-button>
          <edit-button
            v-permission="{action: 'base.middleware.update', effect: 'disabled'}"
            @click="$refs.inputDrawer.show(slotProps.record.id)"
          ></edit-button>
        </template>
        <a-button
          v-else
          icon="monitor"
          size="small"
          type="link"
          @click="monitor(slotProps.record.id)"
          style="color: #1890ff"
        >
          监控
        </a-button>
        <delete-button v-permission="{action: 'base.middleware.delete', effect: 'disabled'}" @confirm="confirm(slotProps.record.id)"></delete-button>
      </template>
    </list-layout>

    <os-drawer ref="osDrawer"></os-drawer>
    <middleware-drawer ref="middlewareDrawer"></middleware-drawer>
    <input-drawer ref="inputDrawer" @ok="confirm"></input-drawer>
  </div>
</template>

<script>
import {
  createMiddlewareGroup,
  deleteMiddleware,
  deleteMiddlewareGroup,
  exportMiddleware,
  getMiddlewareGroup,
  getMiddlewareGroupList,
  getMiddlewareList,
  monitorMiddleware,
  syncMiddleware,
  updateMiddlewareGroup
} from '@/api/middleware'
import ListLayout from '@/components/ListLayout'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import SyncButton from '@/components/button/SyncButton'
import CreateButton from '@/components/button/CreateButton'
import StatusQuestionIcon from '@/components/icon/StatusQuestionIcon'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
import { sourceStatuses, middlewareTypes } from '@/utils/const'
import { hasPermission } from '@/utils'

export default {
  name: 'MiddlewareTable',
  components: {
    DeleteButton,
    EditButton,
    InputDrawer: () => import('./modules/InputDrawer'),
    ListLayout,
    MiddlewareDrawer: () => import('@/components/drawer/MiddlewareDrawer/index.vue'),
    'os-drawer': () => import('@/components/drawer/OSDrawer/index.vue'),
    SourceStatusTag,
    StatusQuestionIcon,
    SyncButton,
    CreateButton
  },
  props: {
    defaultSelected: {
      type: Array
    },
    toolsList: {
      type: Array,
      default: () => []
    },
    tableOperationWidth: {
      type: Number,
      default: 240
    },
    link: {
      type: Boolean,
      default: true
    },
    refresh: {
      type: Boolean
    },
    canOperate: {
      type: Boolean,
      default: true
    },
    inModal: {
      type: Boolean,
      default: false
    },
    fetchParams: {
      type: Object
    }
  },
  data () {
    return {
      getTableDataFunc: getMiddlewareList,
      exportFunc: exportMiddleware,
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'groups',
          title: '所属群组',
          width: 160,
          ellipsis: true,
          scopedSlots: {
            customRender: 'groups'
          }
        },
        {
          dataIndex: 'os.monitor_address',
          title: 'IP 地址',
          width: 160,
          scopedSlots: {
            customRender: 'monitorAddress'
          }
        },
        {
          dataIndex: 'middleware_type',
          title: '中间件类型',
          width: 120,
          scopedSlots: {
            customRender: 'middlewareType'
          },
          filteredValue: [],
          filters: middlewareTypes.map(value => {
            return {
              text: this.$t(`middleware_type.${value}`),
              value
            }
          }),
          filterMultiple: false
        },
        {
          dataIndex: 'os',
          title: '所属操作系统',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'os'
          }
        },
        {
          dataIndex: 'version',
          title: '版本',
          width: 120,
          ellipsis: true,
          scopedSlots: {
            customRender: 'version'
          }
        },
        {
          dataIndex: 'monitor_port',
          title: '监控端口',
          width: 100,
          scopedSlots: {
            customRender: 'monitorPort'
          }
        },
        {
          dataIndex: 'status',
          width: 100,
          slots: {
            title: 'statusTitle',
            name: '状态'
          },
          scopedSlots: {
            customRender: 'status'
          },
          filteredValue: [],
          filters: sourceStatuses.map(value => {
            return {
              text: this.$t(`source_status.${value}`),
              value
            }
          }),
          filterMultiple: false
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        }
      ],
      columnDefaultSelected: [
        'name',
        'groups',
        'middleware_type',
        'os.monitor_address',
        'version',
        'monitor_port',
        'status'
      ],
      updateTableFlag: this.refresh,
      customerParams: this.$route.params,
      tableSelectData: [],
      tableDefaultSelectData: [],
      groupApiPermission: {
        create: 'base.middleware_group.create',
        edit: 'base.middleware_group.update',
        delete: 'base.middleware_group.delete'
      }
    }
  },
  computed: {
    params () {
      const obj = {}
      for (const key in this.customerParams) {
        if (Object.hasOwnProperty.call(this.customerParams, key)) {
          obj[key] = this.customerParams[key]
        }
      }
      for (const key in this.fetchParams) {
        if (Object.hasOwnProperty.call(this.fetchParams, key)) {
          obj[key] = this.fetchParams[key]
        }
      }
      this.columnsAll.forEach(column => {
        if (Object.hasOwnProperty.call(column, 'filteredValue')) {
          if (Object.hasOwnProperty.call(obj, column.dataIndex)) {
            column.filteredValue = [obj[column.dataIndex]]
          } else column.filteredValue = []
        }
      })
      return obj
    },
    groupTreeFunc () {
      if (hasPermission(['base.middleware_group.view'])) {
        return {
          createFunc: createMiddlewareGroup,
          deleteFunc: deleteMiddlewareGroup,
          getFunc: getMiddlewareGroup,
          getListFunc: getMiddlewareGroupList,
          updateFunc: updateMiddlewareGroup
        }
      } else return null
    }
  },
  mounted () {
    if (this.defaultSelected) this.tableDefaultSelectData = this.defaultSelected
  },
  methods: {
    getSelectData (data) {
      this.tableSelectData = data
      this.$emit('selectedData', data)
    },
    async confirm (id = '') {
      if (id) {
        await deleteMiddleware(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(this.tableSelectData.findIndex(item => item.key === id), 1)
        this.tableDefaultSelectData = this.tableSelectData
        this.$emit('selectedData', this.tableSelectData)
      }
      this.updateTableFlag = !this.updateTableFlag
    },
    sync (id) {
      syncMiddleware({ id }).then(res => {
        this.$message.success(res.message)
      })
    },
    monitor (id) {
      monitorMiddleware({ id }).then(res => {
        this.$message.success(res.message)
        this.updateTableFlag = !this.updateTableFlag
        this.$refs.inputDrawer.show(id)
      })
    }
  },
  watch: {
    refresh (v) {
      this.updateTableFlag = v
    }
  }
}
</script>
