<template>
  <div :id="chartId"></div>
</template>

<script>
import { redColor, orangeColor, blueColor } from '@/utils/const.js'
import { Chart } from '@antv/g2'

const chart = {}

export default {
  name: 'UsageChart',
  props: {
    chartData: {
      type: Array
    },
    height: {
      type: Number,
      default: 270
    },
    chartId: {
      type: String
    }
  },
  computed: {
    color () {
      if (this.detail.usage > 80) return redColor
      if (this.detail.usage > 60) return orangeColor
      return blueColor
    }
  },
  data () {
    return {}
  },
  mounted () {
    this.initChart()
  },
  methods: {
    initChart () {
      chart[this.chartId] = new Chart({
        container: this.chartId,
        autoFit: true,
        height: this.height,
        appendPadding: [16, 8, 0, 16]
      })
      chart[this.chartId].legend(false)

      chart[this.chartId].scale('value', {
        min: 0,
        max: 100
      })

      chart[this.chartId].axis('name', {
        tickLine: null,
        line: null,
        grid: null,
        label: {
          style: {
            fill: '#808080'
          }
        }
      })

      chart[this.chartId].coordinate().transpose()

      chart[this.chartId].axis('value', {
        grid: {
          line: {
            style: {
              stroke: '#cccccc',
              strokeOpacity: 0.8,
              lineWidth: 1,
              lineDash: [4]
            }
          }
        },
        label: {
          style: {
            fill: '#808080'
          }
        }
      })

      chart[this.chartId]
        .interval(
          {
            background: {
              style: {
                radius: 0
              }
            }
          }
        )
        .position('name*value')
        .color('value', (val) => {
          if (val >= 90) {
            return redColor
          } else if (val >= 60) {
            return orangeColor
          } else {
            return blueColor
          }
        })
        .tooltip('name*value', (name, value) => {
          return {
            name,
            value: value + '%'
          }
        }).label('value', {
          content: (item) => {
            return item.value + '%'
          }
        })
      chart[this.chartId].interaction('active-region')

      this.render()
    },
    render () {
      if (this.chartData.length === 0) {
        chart[this.chartId].changeVisible(false)
      } else {
        chart[this.chartId].changeData(this.chartData)
        chart[this.chartId].changeVisible(true)
      }
    }
  },
  watch: {
    chartData (v) {
      this.render()
    }
  }
}
</script>

<style lang="less">
</style>
