<template>
  <div class="software-page" v-if="detail.id">
    <a-row :gutter="16">
      <a-col :span="24" style="margin-bottom: 16px">
        <div class="module-container" style="height: 400px">
          <header>CPU</header>
          <usage-card :source-id="detail.id" :item-key="itemKeys.cpu">
            <a-descriptions :column="1" style="padding: 0 16px 8px 16px">
              <a-descriptions-item label="当前使用率">
                {{ detail.cpu_usage ? detail.cpu_usage : 0 }}%
              </a-descriptions-item>
            </a-descriptions>
          </usage-card>
        </div>
      </a-col>
      <a-col :span="24" style="margin-bottom: 16px">
        <div class="module-container" style="height: 400px">
          <header>内存</header>
          <usage-card
            software-type="memory"
            :source-id="detail.id"
            :item-key="itemKeys.memory"
          >
            <a-descriptions :column="1" style="padding: 0 16px 8px 16px">
              <a-descriptions-item label="当前使用率">
                {{ detail.memory_usage ? detail.memory_usage : 0 }}%
              </a-descriptions-item>
            </a-descriptions>
          </usage-card>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'SoftwarePage',
  components: {
    UsageCard: () => import('./modules/UsageCard')
  },
  props: {
    detail: {
      type: Object
    }
  },
  data () {
    return {
      itemKeys: {
        cpu: 'system.cpu.util',
        memory: 'vm.memory.util'
      }
    }
  }
}
</script>

<style lang="less">
.software-page {
  .module-container {
    position: relative;
    background: #fff;
    border-radius: 6px;
    padding: 16px 16px 8px 16px;
    margin-right: 1px;
    overflow: hidden;
    border: 1px solid #e5e5e5;

    header {
      font-size: 16px;
      font-weight: 500;
      color: #096dd9;
      line-height: 22px;
    }

    .ant-descriptions-item-content {
      font-size: 14px;
    }
  }
}
</style>
