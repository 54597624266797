<template>
  <div class="status-question-icon">
    <template v-if="isInTable">
      <a-space>
        <span>状态</span>
        <a-tooltip>
          <template slot="title">
            <div v-for="status in statuses" :key="status.name">
              <a-badge :color="status.color"></a-badge>
              <span>{{ `${status.name} - ${status.description}` }}</span>
            </div>
          </template>

          <a-icon
            type="question-circle"
            style="color: rgba(0, 0, 0, 0.45)"
          ></a-icon>
        </a-tooltip>
      </a-space>
    </template>
    <div v-else style="display: flex;">
      <div v-for="status in statuses" :key="status.name" style="margin-right: 24px;">
        <a-badge :color="status.color"></a-badge>
        <span class="text">{{ status.name }}</span>
        <a-tooltip :title="status.description">
          <a-icon class="question-icon" type="question-circle" />
        </a-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { grayColor, greenColor, orangeColor, redColor } from '@/utils/const'

export default {
  name: 'StatusQuestionIcon',
  props: {
    isInTable: {
      type: Boolean,
      default: true
    },
    isServiceTopology: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    statuses () {
      const arr = [
        {
          name: this.$t('source_status.normal'),
          description: '监控状态正常，无告警',
          color: greenColor
        },
        {
          name: this.$t('source_status.alert'),
          description: '监控状态正常，有告警',
          color: orangeColor
        },
        {
          name: this.$t('source_status.abnormal'),
          description: '监控状态异常',
          color: redColor
        },
        {
          name: this.$t('source_status.unknown'),
          description: '监控状态未知',
          color: grayColor
        }
      ]
      if (this.isServiceTopology) {
        arr[1] = {
          name: `普通${this.$t('source_status.alert')}`,
          description: '监控状态正常，有普通、预警级别告警',
          color: orangeColor
        }
        arr[2] = {
          name: `${this.$t('source_status.abnormal')} 或 紧急告警`,
          description: '监控状态异常 或 有紧急、严重级别告警',
          color: redColor
        }
      }
      return arr
    }
  }
}
</script>

<style lang="less">
.status-question-icon{
.ant-badge-status-dot {
  width: 8px;
  height: 8px;
}
.text {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0,0,0,0.85);
  line-height: 17px;
  }
  .question-icon {
    color: #333333;
    opacity: 0.3;
    margin-left: 5px;
    margin-bottom: 2px;
    cursor: pointer;
    transition: all .3s;
    font-size: 13px;

    &:hover {
      color: #1574DA;
      opacity: 1;
    }
  }
}
</style>
