<template>
  <a-spin :spinning="spinning">
    <div :id="chartId" ></div>
    <!-- <a-empty v-else style="min-height: 400px;"></a-empty> -->
  </a-spin>
</template>

<script>
import moment from 'moment'
import { Chart } from '@antv/g2'
import { humanizeValue } from '@/utils'
import { chartColors } from '@/utils/const'

let chart

export default {
  name: 'MultiLineChart',
  props: {
    compareLastWeek: {
      type: Boolean,
      default: false
    },
    compareYesterday: {
      type: Boolean,
      default: false
    },
    datetimeRange: {
      type: Array,
      required: true
    },
    historyFunc: {
      type: Function,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    inKey: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    outKey: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      spinning: false,
      y: 0,
      offset: 0,
      chartData: []
    }
  },
  computed: {
    chartId () {
      return `${this.name}.chart.modal`
    }
  },
  mounted () {
    this.render()
  },
  methods: {
    async render () {
      if (chart) chart.destroy()
      chart = new Chart({
        container: this.chartId,
        autoFit: true,
        height: 400,
        appendPadding: [8, 16, 8, 64]
      })
      chart.scale({
        timestamp: {
          type: 'time',
          mask: 'YYYY-MM-DD HH:mm'
        },
        value: {
          min: 0,
          nice: true,
          alias: '值',
          formatter: value => humanizeValue(value)
        }
      })
      chart.legend(false)
      chart.tooltip({
        shared: true,
        showCrosshairs: true
      })
      chart.removeInteraction('tooltip')
      const datetimeRangeList = [this.datetimeRange]
      if (this.compareYesterday) {
        datetimeRangeList.push([
          moment(this.datetimeRange[0]).subtract(1, 'day'),
          moment(this.datetimeRange[1]).subtract(1, 'day')
        ])
      }
      if (this.compareLastWeek) {
        datetimeRangeList.push([
          moment(this.datetimeRange[0]).subtract(1, 'week'),
          moment(this.datetimeRange[1]).subtract(1, 'week')
        ])
      }
      const offset = 1 / datetimeRangeList.length
      this.spinning = true
      for (let i = 0; i < datetimeRangeList.length; i++) {
        const chartView = chart.createView({
          region: {
            start: {
              x: 0,
              y: offset * i
            },
            end: {
              x: 1,
              y: offset * i + offset
            }
          }
        })
        chartView.facet('mirror', {
          fields: ['name'],
          showTitle: false,
          padding: [0],
          eachView (view, facet) {
            const rowIndex = facet.rowIndex
            view
              .line()
              .position('timestamp*value')
              .color('name', chartColors[rowIndex])
            view
              .area()
              .position('timestamp*value')
              .color('name', chartColors[rowIndex])
            const data = facet.data
            if (data.length !== 0) {
              let max = data[0]
              let min = data[0]
              for (const datum of data) {
                if (datum.value > max.value) max = datum
                if (datum.value < min.value) min = datum
              }
              view.annotation().dataMarker({
                point: {
                  style: {
                    stroke: '#ff9671'
                  }
                },
                direction: 'downward',
                top: true,
                position: [max.timestamp, max.value],
                text: {
                  content: `峰值: ${humanizeValue(max.value)}`
                }
              })
              if (max.timestamp !== min.timestamp) {
                view.annotation().dataMarker({
                  point: {
                    style: {
                      stroke: '#ff9671'
                    }
                  },
                  top: true,
                  position: [min.timestamp, min.value],
                  text: {
                    content: `谷值: ${humanizeValue(min.value)}`
                  }
                })
              }
            }
          }
        })
        const chartData = []
        await this.historyFunc(this.id, {
          key: `net.if.in[${this.inKey}]`,
          datetime_from: datetimeRangeList[i][0].format('YYYY-MM-DD HH:mm'),
          datetime_to: datetimeRangeList[i][1].format('YYYY-MM-DD HH:mm')
        }).then(res => {
          res.data.data.forEach(item => {
            chartData.push({
              timestamp: item.timestamp,
              name: '入流量',
              value: item.value
            })
          })
        })
        await this.historyFunc(this.id, {
          key: `net.if.out[${this.outKey}]`,
          datetime_from: datetimeRangeList[i][0].format('YYYY-MM-DD HH:mm'),
          datetime_to: datetimeRangeList[i][1].format('YYYY-MM-DD HH:mm')
        }).then(res => {
          res.data.data.forEach(item => {
            chartData.push({
              timestamp: item.timestamp,
              name: '出流量',
              value: item.value
            })
          })
        })
        this.chartData = chartData
        chartView.data(chartData)
        chartView.interaction('tooltip')
        chartView.interaction('sibling-tooltip')
      }
      chart.render()
      this.spinning = false
    }
  },
  watch: {
    datetimeRange () {
      this.render()
    },
    compareYesterday () {
      this.render()
    },
    compareLastWeek () {
      this.render()
    }
  }
}
</script>
