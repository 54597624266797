<template>
  <a-button
    icon="block"
    size="small"
    type="link"
    @click="e => $emit('click', e)"
    style="color: #2196f3"
  >
    克隆
  </a-button>
</template>

<script>
export default {
  name: 'CloneButton'
}
</script>
