<template>
  <a-tabs
    id="request-type-radio-button"
    size="small"
    @change="change"
    :tabBarStyle="{
      'borderBottom': 'none'
    }"
    :tabBarGutter=0
  >
    <a-tab-pane v-for="item in options" :key="item.value" :tab="item.label"></a-tab-pane>
  </a-tabs>
</template>

<script>
export default {
  name: 'RequestTypeRadio',
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  methods: {
    change (v) {
      this.$emit('change', v)
    }
  }
}
</script>
<style lang="less">
#request-type-radio-button {
  .ant-tabs-nav-container {
    margin: 0 !important;
    height: 24px;
    .ant-tabs-nav-wrap {
      border-radius: 16px;
      .ant-tabs-tab {
        border-radius: 16px;
        font-size: 12px;
        color: #686e7e;
        padding: 0 10px;
        line-height: 24px;
        height: 24px;
      }
      .ant-tabs-tab-active {
        background: #096dd9;
        color: #fff;
      }
      .ant-tabs-ink-bar {
        width: 0 !important;
      }
    }
  }
}
</style>
