<template>
  <a-drawer
    class="detail-drawer"
    :closable="false"
    :body-style="{ padding: '8px 16px', overflow: 'hidden' }"
    :destroy-on-close="true"
    :visible="visible"
    :width="1080"
    @close="closeDrawer"
  >
    <a-tabs v-model="tabKey" v-if="detail.id">
      <a-tab-pane key="overview" tab="总览">
        <a-row
          :gutter="16"
          style="flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto"
        >
          <!-- 健康仪表盘 -->
          <a-col :span="6" style="margin-bottom: 16px">
            <div class="module-container" style="height: 208px">
              <div
                style="
                  height: 60px;
                  margin-bottom: 8px;
                  display: grid;
                  grid-template-rows: 1fr 1fr;
                  justify-items: center;
                  grid-template-columns: 2fr 3fr;
                  line-height: 30px;
                "
              >
                <div style="color: rgba(0, 0, 0, 0.5); font-size: 12px;">
                  资源状态
                </div>
                <source-status-tag :status="detail.status"></source-status-tag>
                <div style="color: rgba(0, 0, 0, 0.5); font-size: 12px;">
                  监控状态
                </div>
                <div>
                  <monitor-status-tag
                    v-for="item in monitorStatuses"
                    :key="item.name"
                    :status="item.value"
                    :name="item.name"
                  ></monitor-status-tag>
                </div>
              </div>
              <dashboard-chart
                v-if="showScoreChart"
                chart-id="overview-health-chart"
                :height="110"
                :chart-data="dashboardData"
              ></dashboard-chart>
            </div>
          </a-col>
          <!-- 硬件信息 -->
          <a-col :span="6" style="margin-bottom: 16px">
            <div class="module-container" style="height: 208px">
              <div class="module-header-fs14" style="margin-bottom: 14px;">硬件信息</div>
              <div
                style="
                  position: absolute;
                  right: 10px;
                  top: 12px;
                  font-size: 11px;
                "
              >
                <a @click="$refs.hardwareModal.show()">更多</a>
              </div>

              <hardware-description :detail="detail"></hardware-description>
              <info-modal ref="hardwareModal" class="description-in-modal">
                <template>
                  <hardware-description :detail="detail" :show-more="true"></hardware-description>
                </template>
              </info-modal>
            </div>
          </a-col>
          <!-- 资产信息 -->
          <a-col :span="6" style="margin-bottom: 16px">
            <div class="module-container" style="height: 208px">
              <div class="module-header-fs14" style="margin-bottom: 14px;">资产信息</div>
              <div
                style="
                  position: absolute;
                  right: 10px;
                  top: 12px;
                  font-size: 11px;
                "
              >
                <a @click="$refs.assetModal.show()">更多</a>
              </div>
              <asset-description :detail="detail"></asset-description>
              <info-modal ref="assetModal" class="description-in-modal">
                <template>
                  <asset-description :detail="detail" :show-more="true"></asset-description>
                </template>
              </info-modal>
            </div>
          </a-col>
          <!-- 监控信息 -->
          <a-col :span="6" style="margin-bottom: 16px">
            <div class="module-container" style="height: 208px">
              <div class="module-header-fs14" style="margin-bottom: 14px;">监控信息</div>
              <a-descriptions
                :column="1"
                class="description-overflow-hidden description-item-12px"
              >
                <a-descriptions-item label="ID">
                  <content-tooltip
                    v-if="detail.id"
                    :title="detail.id.replaceAll('-', '')"
                    :body="detail.id.replaceAll('-', '')"
                  ></content-tooltip>
                  <span v-else>-</span>
                </a-descriptions-item>
                <a-descriptions-item label="所属群组">
                  <a-tag v-for="group in detail.groups" :key="group.id">
                    {{ group.name }}
                  </a-tag>
                </a-descriptions-item>
                <a-descriptions-item label="IP 地址">
                  <content-tooltip
                    v-if="detail.monitor_address"
                    :title="detail.monitor_address"
                    :body="detail.monitor_address"
                  ></content-tooltip>
                  <span v-else>-</span>
                </a-descriptions-item>
                <a-descriptions-item label="监控端口">
                  <content-tooltip
                    v-if="detail.monitor_port"
                    :title="detail.monitor_port"
                    :body="detail.monitor_port"
                  ></content-tooltip>
                  <span v-else>-</span>
                </a-descriptions-item>
                <a-descriptions-item label="SNMP 版本">
                  <content-tooltip
                    v-if="detail.snmp_version"
                    :title="$t(`snmp_version.${detail.snmp_version}`)"
                    :body="$t(`snmp_version.${detail.snmp_version}`)"
                  ></content-tooltip>
                  <span v-else>-</span>
                </a-descriptions-item>
              </a-descriptions>
            </div>
          </a-col>
          <!-- 健康情况 -->
          <a-col :span="24" style="margin-bottom: 16px">
            <div
              class="module-container health-module-container"
              style="min-height: 340px; max-height: 470px"
            >
              <div class="module-header-fs14" style="margin-bottom: 14px;">硬件健康情况</div>
              <div class="module-header-fs14" style="position: absolute;right: 50%; top: 16px; transform: translateX(80px);">性能情况</div>
              <a-col
                :span="12"
                style="
                  display: grid;
                  grid-template-columns: repeat(auto-fill, 114px);
                  align-items: end;
                  grid-row-gap: 16px;
                  justify-content: space-evenly;
                "
              >
                <circle-status
                  v-for="item in healthyStatus"
                  :key="item.type"
                  :detail="item"
                ></circle-status>
              </a-col>
              <a-col :span="12">
                <usage-chart
                  :chartData="healthyUsage"
                  chart-id="switch-healthy-usage-chart"
                ></usage-chart>
              </a-col>
            </div>
          </a-col>
          <!-- 告警情况 -->
          <a-col :span="24" style="margin-bottom: 16px">
            <div class="module-container" style="height: 430px">
              <div class="module-header-fs14" style="margin-bottom: 14px;">告警情况</div>
              <overview-alerts :dataSource="alertsData" :loading="loading"></overview-alerts>
            </div>
          </a-col>
          <!-- 风险情况 -->
          <!-- <a-col :span="24" style="margin-bottom: 16px">
            <div class="module-container" style="height: 340px">
              <div class="module-header-fs14" style="margin-bottom: 14px;">风险情况</div>
              <display-table :columns="RiskColumns"></display-table>
            </div>
          </a-col> -->
        </a-row>
      </a-tab-pane>

      <a-tab-pane key="hardware" tab="硬件运行情况">
        <hardware-page :detail="detail"></hardware-page>
      </a-tab-pane>

      <a-tab-pane key="software" tab="软件运行情况">
        <software-page :detail="detail" @cpuUsage="v => detail.cpu_usage = v" @memoryUsage="v => detail.memory_usage = v"></software-page>
      </a-tab-pane>

      <a-tab-pane key="network" tab="网络运行情况">
        <network-page :detail="detail"></network-page>
      </a-tab-pane>

      <a-tab-pane key="monitor" tab="指标详情">
        <div
          style="
            padding: 0 16px;
            flex-flow: nowrap;
            height: calc(100vh - 80px);
            overflow: auto;
          "
        >
          <monitor-item-table
            :get-func="getItemListFunc"
            :history-func="historyFunc"
            :source-id="detail.id"
          ></monitor-item-table>
        </div>
      </a-tab-pane>

      <a-tab-pane key="rule" tab="告警规则">
        <div
          style="
            padding: 0 16px;
            flex-flow: nowrap;
            height: calc(100vh - 80px);
            overflow: auto;
          "
        >
          <monitor-trigger-table
            :get-func="getTriggerFunc"
            :source-id="detail.id"
            :update-func="updateFunc"
          ></monitor-trigger-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="alert" tab="历史告警">
        <div style="padding: 0 16px; flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
          <alert-table source-type="network_device" :source-id="detail.id"></alert-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script>
import store from '@/store'
import {
  getNetworkDevice,
  getNetworkDeviceMonitorStatusList,
  getNetworkDeviceMonitorTriggerList,
  updateNetworkDeviceMonitorTrigger,
  getNetworkDeviceMonitorHistory,
  getNetworkDeviceMonitorItemList,
  getNetworkDeviceHealthStatusList,
  getNetworkDeviceAlertList,
  getNetworkDeviceHealthScore
} from '@/api/network-device'
import MonitorStatusTag from '@/components/tag/MonitorStatusTag'
import ContentTooltip from '@/components/ContentTooltip'
import SourceStatusTag from '@/components/tag/SourceStatusTag'

import DashboardChart from '@/components/chart/DashboardChart'
import HardwareDescription from './modules/HardwareDescription'
import AssetDescription from './modules/AssetDescription'
import InfoModal from '@/components/source-detail-page/InfoModal'
import CircleStatus from '@/components/source-detail-page/CircleStatus'
import UsageChart from '@/components/source-detail-page/UsageChart'
import MonitorTriggerTable from '@/components/table/MonitorTriggerTable.vue'
import MonitorItemTable from '@/components/table/MonitorItemTable.vue'
import OverviewAlerts from '@/components/source-detail-page/OverviewAlerts'

import HardwarePage from './HardwarePage/index.vue'
import SoftwarePage from './SoftwarePage/index.vue'
import NetworkPage from './NetworkPage/index.vue'
import AlertTable from '@/components/table/AlertTable.vue'

const hardwareStatusKeys = [
  { key: 'system.cpu.util', type: 'cpu' },
  { key: 'vm.memory.util', type: 'memory' }
]

export default {
  name: 'SwitchDrawer',
  components: {
    AlertTable,
    MonitorStatusTag,
    DashboardChart,
    HardwareDescription,
    AssetDescription,
    InfoModal,
    ContentTooltip,
    CircleStatus,
    UsageChart,
    MonitorTriggerTable,
    MonitorItemTable,
    HardwarePage,
    SoftwarePage,
    NetworkPage,
    OverviewAlerts,
    SourceStatusTag
  },
  data () {
    return {
      visible: false,
      tabKey: 'overview',
      getTriggerFunc: getNetworkDeviceMonitorTriggerList,
      updateFunc: updateNetworkDeviceMonitorTrigger,
      historyFunc: getNetworkDeviceMonitorHistory,
      getItemListFunc: getNetworkDeviceMonitorItemList,
      monitorStatuses: [],
      dashboardData: [],
      detail: {
        id: '',
        name: '',
        monitor_address: '',
        monitor_port: 161,
        device_type: undefined,
        vendor: undefined,
        model: '',
        firmware_version: '',
        serial_number: '',
        snmp_version: 'snmp_v2c',
        snmp_community: '',
        created_at: '',
        updated_at: '',
        groups: [],
        status: undefined
      },
      RiskColumns: [
        {
          dataIndex: 'type',
          align: 'center',
          title: '风险分类',
          width: 80,
          scopedSlots: {
            customRender: 'type'
          }
        },
        {
          dataIndex: 'monitor',
          align: 'center',
          title: '监控项',
          width: 80,
          scopedSlots: {
            customRender: 'monitor'
          }
        },
        {
          dataIndex: 'current_monitor',
          align: 'center',
          title: '当前指标',
          width: 80,
          scopedSlots: {
            customRender: 'currentMonitor'
          }
        },
        {
          dataIndex: 'description',
          align: 'description',
          title: '描述',
          width: 80,
          scopedSlots: {
            customRender: 'description'
          }
        }
      ],
      alertsData: [],
      healthyStatus: [],
      healthyUsage: [
        {
          type: 'cpu_usage',
          name: 'CPU',
          value: 0
        },
        {
          type: 'memory_usage',
          name: '内存',
          value: 0
        }
      ],
      loading: false,
      showScoreChart: false
    }
  },
  methods: {
    show (id) {
      getNetworkDevice(id)
        .then(res => {
          this.detail = res.data
          this.fetchUsage(id)
        }).finally(() => {
          this.visible = true
        })
      getNetworkDeviceMonitorStatusList(id).then(res => {
        this.monitorStatuses = res.data.data
      })
      const params = {
        recovered: false,
        source_id: id
      }
      this.loading = true
      getNetworkDeviceAlertList(params).then(res => {
        this.alertsData = res.data.data
      }).finally(() => {
        this.loading = false
      })
      getNetworkDeviceHealthStatusList(id).then(res => {
        this.healthyStatus = res.data.data
      })

      getNetworkDeviceHealthScore(id).then(res => {
        this.dashboardData = [{ value: res.data }]
      }).finally(() => {
        this.showScoreChart = true
      })
    },
    fetchUsage (id) {
      const arr = [
        {
          type: 'cpu_usage',
          name: 'CPU',
          value: 0
        },
        {
          type: 'memory_usage',
          name: '内存',
          value: 0
        }
      ]
      Promise.all(hardwareStatusKeys.map(keyObj => new Promise((resolve, reject) => {
        getNetworkDeviceMonitorItemList(id, { key: keyObj.key }).then(res => {
          const data = res.data.data
          if (data.length) {
            const v = data[0].value * 1
            resolve(v)
          } else resolve(0)
        }).catch(err => {
          reject(err)
        })
      }))).then(res => {
        arr.forEach((item, i) => {
          item.value = res[i]
        })
        this.healthyUsage = arr
        this.detail.cpu_usage = res[0]
        this.detail.memory_usage = res[1]
      })
    },
    closeDrawer () {
      this.visible = false
      store.state.cancelRequest.promiseArr.forEach((e, index) => {
        e.cancel('取消请求')
      })
      store.commit('cancelRequest/CLEAR_PROMISE_ARR')
      this.detail = {
        id: '',
        name: '',
        monitor_address: '',
        monitor_port: 161,
        device_type: undefined,
        vendor: undefined,
        model: '',
        firmware_version: '',
        serial_number: '',
        snmp_version: 'snmp_v2c',
        snmp_community: '',
        created_at: '',
        updated_at: '',
        groups: [],
        status: undefined
      }
      this.tabKey = 'overview'
      this.healthyUsage = [
        {
          type: 'cpu_usage',
          name: 'CPU',
          value: 0
        },
        {
          type: 'memory_usage',
          name: '内存',
          value: 0
        }
      ]
      this.alertsData = []
      this.healthyStatus = []
      this.monitorStatuses = []
      this.showScoreChart = false
    }
  }
}
</script>

<style>

</style>
