<template>
  <div>
    <a-select
      v-model="selectedData"
      ref="sourceSelect"
      :disabled="disabled"
      :label-in-value="true"
      mode="multiple"
      :open="false"
      :options="selectedData"
      :show-arrow="false"
      @change="change"
      @focus="focus"
    ></a-select>

    <a-modal
      :destroy-on-close="true"
      :title="$t(`source_type.${sourceType}`)"
      :visible="visible"
      :width="1200"
      @cancel="visible = false"
      @ok="handleOk"
    >
      <network-device-table
        v-if="sourceType === 'network_device'"
        :can-operate="false"
        :default-selected="selectedData"
        :in-modal="true"
        :table-operation-width="0"
        :tools-list="['reload', 'search']"
        @selectedData="getSelectedData"
      ></network-device-table>
      <storage-table
        v-else-if="sourceType === 'storage'"
        :can-operate="false"
        :default-selected="selectedData"
        :in-modal="true"
        :table-operation-width="0"
        :tools-list="['reload', 'search']"
        @selectedData="getSelectedData"
      ></storage-table>
      <server-table
        v-else-if="sourceType === 'server'"
        :can-operate="false"
        :default-selected="selectedData"
        :in-modal="true"
        :table-operation-width="0"
        :tools-list="['reload', 'search']"
        @selectedData="getSelectedData"
      ></server-table>
      <os-table
        v-else-if="sourceType === 'os'"
        :can-operate="false"
        :default-selected="selectedData"
        :in-modal="true"
        :table-operation-width="0"
        :tools-list="['reload', 'search']"
        @selectedData="getSelectedData"
      ></os-table>
      <middleware-table
        v-else-if="sourceType === 'middleware'"
        :can-operate="false"
        :default-selected="selectedData"
        :in-modal="true"
        :table-operation-width="0"
        :fetchParams="{ discovered: false }"
        :tools-list="['reload', 'search']"
        @selectedData="getSelectedData"
      ></middleware-table>
      <database-table
        v-else-if="sourceType === 'database'"
        :can-operate="false"
        :default-selected="selectedData"
        :in-modal="true"
        :table-operation-width="0"
        :fetchParams="{ discovered: false }"
        :tools-list="['reload', 'search']"
        @selectedData="getSelectedData"
      ></database-table>
      <hypervisor-table
        v-else-if="sourceType === 'hypervisor'"
        :can-operate="false"
        :default-selected="selectedData"
        :in-modal="true"
        :table-operation-width="0"
        :tools-list="['reload', 'search']"
        @selectedData="getSelectedData"
      ></hypervisor-table>
      <site-table
        v-else-if="sourceType === 'site'"
        :default-selected="selectedData"
        :in-modal="true"
        :table-operation-width="0"
        :tools-list="['reload', 'search']"
        @selectedData="getSelectedData"
      ></site-table>
      <domain-table
        v-else-if="sourceType === 'domain'"
        :can-operate="false"
        :default-selected="selectedData"
        :in-modal="true"
        :table-operation-width="0"
        :tools-list="['reload', 'search']"
        @selectedData="getSelectedData"
      ></domain-table>
      <ssl-certificate-table
        v-else-if="sourceType === 'ssl_certificate'"
        :can-operate="false"
        :default-selected="selectedData"
        :in-modal="true"
        :table-operation-width="0"
        :tools-list="['reload', 'search']"
        @selectedData="getSelectedData"
      ></ssl-certificate-table>
      <application-table
        v-else-if="sourceType === 'application'"
        :default-selected="selectedData"
        :in-modal="true"
        :tableOperationWidth="0"
        :tools-list="['reload', 'search']"
        @selectedData="getSelectedData"
      ></application-table>
    </a-modal>
  </div>
</template>

<script>
import ApplicationTable from '@/components/table/ApplicationTable'
import DatabaseTable from '@/components/table/DatabaseTable'
import DomainTable from '@/components/table/DomainTable'
import HypervisorTable from '@/components/table/HypervisorTable'
import MiddlewareTable from '@/components/table/MiddlewareTable/index.vue'
import NetworkDeviceTable from '@/components/table/NetworkDeviceTable'
import OSTable from '@/components/table/OSTable'
import SSLCertificateTable from '@/components/table/SSLCertificateTable'
import ServerTable from '@/components/table/ServerTable'
import SiteTable from '@/components/table/SiteTable'
import StorageTable from '@/components/table/StorageTable'

export default {
  name: 'MultiSourceSelect',
  components: {
    ApplicationTable,
    DatabaseTable,
    DomainTable,
    HypervisorTable,
    MiddlewareTable,
    NetworkDeviceTable,
    StorageTable,
    ServerTable,
    'os-table': OSTable,
    SiteTable,
    'ssl-certificate-table': SSLCertificateTable
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    sourceType: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      visible: false,
      selectedData: [],
      tableSelectedData: []
    }
  },
  mounted () {
    if (this.value) {
      this.selectedData = this.value.map(item => {
        return {
          key: item.id,
          label: item.name
        }
      })
    }
  },
  methods: {
    handleOk () {
      this.selectedData = this.tableSelectedData
      this.$emit(
        'input',
        this.selectedData.map(item => {
          return {
            id: item.key,
            name: item.label
          }
        })
      )
      this.visible = false
    },
    change (v) {
      this.$emit(
        'input',
        this.selectedData.map(item => {
          return {
            id: item.key,
            name: item.label
          }
        })
      )
    },
    focus () {
      this.$refs.sourceSelect.blur()
      this.visible = true
    },
    getSelectedData (data) {
      this.tableSelectedData = data
    }
  },
  watch: {
    sourceType () {
      this.selectedData = []
      this.tableSelectData = []
    },
    value (v) {
      this.selectedData = v.map(item => {
        return {
          key: item.id,
          label: item.name
        }
      })
    }
  }
}
</script>
