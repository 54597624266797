<template>
  <div>
    <list-layout
      :can-operate="canOperate"
      :column-default-selected="columnDefaultSelected"
      :columns-all="columnsAll"
      :default-selected="tableDefaultSelectData"
      :export-func="exportFunc"
      :get-table-data-func="getTableDataFunc"
      :group-tree-func="groupTreeFunc"
      :in-modal="inModal"
      :refresh="updateTableFlag"
      :route-params="$route.params"
      :table-operation-width="tableOperationWidth"
      :tools-list="toolsList"
      @tableSelectData="getSelectData"
      :apiPermission="groupApiPermission"
      :can-drag-column-width="true"
    >
      <template #leftTool>
        <create-button v-permission="{action: 'service.domain.create', effect: 'disabled'}" @click="$refs.inputDrawer.show()">
        </create-button>
      </template>
      <!-- 表格自定义列 -->
      <template #name="{ slotProps }">
        <a-tooltip v-if="!isValid(slotProps.record.expires_at)" title="已到期" style="margin-right: 4px">
          <a-icon theme="filled" type="info-circle" style="color: #ff4d4f"></a-icon>
        </a-tooltip>
        <a-tooltip placement="topLeft" :title="slotProps.text">
          {{ slotProps.text }}
        </a-tooltip>
      </template>
      <template #nameServers="{ slotProps }">
        <div v-for="server in slotProps.record.name_servers" :key="server">
          {{ server }}
        </div>
      </template>
      <template #statusTitle>
        <status-question-icon></status-question-icon>
      </template>
      <template #status="{ slotProps }">
        <source-status-tag :status="slotProps.text"></source-status-tag>
      </template>
      <template #operation="{ slotProps }">
        <sync-button @click="sync(slotProps.record.id)"></sync-button>
        <edit-button
        v-permission="{action: 'service.domain.update', effect: 'disabled'}"
          @click="$refs.inputDrawer.show(slotProps.record.id)"
        ></edit-button>
        <delete-button v-permission="{action: 'service.domain.delete', effect: 'disabled'}" @confirm="confirm(slotProps.record.id)"></delete-button>
      </template>
    </list-layout>

    <input-drawer ref="inputDrawer" @ok="confirm"></input-drawer>
  </div>
</template>

<script>
import moment from 'moment'
import {
  createDomainGroup,
  deleteDomain,
  deleteDomainGroup,
  exportDomain,
  getDomainGroup,
  getDomainGroupList,
  getDomainList,
  syncDomain,
  updateDomainGroup
} from '@/api/domain'
import ListLayout from '@/components/ListLayout'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import CreateButton from '@/components/button/CreateButton'
import SyncButton from '@/components/button/SyncButton'
import StatusQuestionIcon from '@/components/icon/StatusQuestionIcon'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
import { sourceStatuses } from '@/utils/const'
import { hasPermission } from '@/utils'

export default {
  name: 'DomainTable',
  components: {
    SourceStatusTag,
    DeleteButton,
    EditButton,
    CreateButton,
    InputDrawer: () => import('./modules/InputDrawer'),
    ListLayout,
    StatusQuestionIcon,
    SyncButton
  },
  props: {
    defaultSelected: {
      type: Array
    },
    toolsList: {
      type: Array,
      default: () => []
    },
    tableOperationWidth: {
      type: Number,
      default: 240
    },
    link: {
      type: Boolean,
      default: true
    },
    refresh: {
      type: Boolean
    },
    canOperate: {
      type: Boolean,
      default: true
    },
    inModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      getTableDataFunc: getDomainList,
      exportFunc: exportDomain,
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'registrar',
          title: '注册商',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'registrar'
          }
        },
        {
          dataIndex: 'registered_at',
          title: '注册时间',
          width: 200
        },
        {
          dataIndex: 'expires_at',
          title: '到期时间',
          width: 200
        },
        {
          dataIndex: 'name_servers',
          title: 'DNS',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'nameServers'
          }
        },
        {
          dataIndex: 'status',
          width: 100,
          slots: {
            title: 'statusTitle',
            name: '状态'
          },
          scopedSlots: {
            customRender: 'status'
          },
          filters: sourceStatuses.map(value => {
            return {
              text: this.$t(`source_status.${value}`),
              value
            }
          }),
          filterMultiple: false
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        }
      ],
      columnDefaultSelected: [
        'name',
        'registrar',
        'registered_at',
        'expires_at',
        'name_servers',
        'status'
      ],
      updateTableFlag: this.refresh,
      tableSelectData: [],
      tableDefaultSelectData: [],
      groupApiPermission: {
        create: 'service.domain_group.create',
        edit: 'service.domain_group.update',
        delete: 'service.domain_group.delete'
      }
    }
  },
  computed: {
    groupTreeFunc () {
      if (hasPermission(['service.domain_group.view'])) {
        return {
          createFunc: createDomainGroup,
          deleteFunc: deleteDomainGroup,
          getFunc: getDomainGroup,
          getListFunc: getDomainGroupList,
          updateFunc: updateDomainGroup
        }
      } else return null
    }
  },
  mounted () {
    if (this.defaultSelected) this.tableDefaultSelectData = this.defaultSelected
  },
  methods: {
    isValid (expiresAt) {
      return moment.now() < moment(expiresAt, 'YYYY-MM-DD HH:mm:ss')
    },
    getSelectData (data) {
      this.tableSelectData = data
      this.$emit('selectedData', data)
    },
    sync (id) {
      syncDomain({ id }).then(res => {
        this.$message.success(res.message)
      })
    },
    async confirm (id = '') {
      if (id) {
        await deleteDomain(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(this.tableSelectData.findIndex(item => item.key === id), 1)
        this.tableDefaultSelectData = this.tableSelectData
        this.$emit('selectedData', this.tableSelectData)
      }
      this.updateTableFlag = !this.updateTableFlag
    }
  },
  watch: {
    refresh (v) {
      this.updateTableFlag = v
    }
  }
}
</script>
