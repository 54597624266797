var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('list-layout',{attrs:{"can-operate":_vm.canOperate,"column-default-selected":_vm.columnDefaultSelected,"columns-all":_vm.columnsAll,"default-selected":_vm.tableDefaultSelectData,"export-func":_vm.exportFunc,"get-table-data-func":_vm.getTableDataFunc,"group-tree-func":_vm.groupTreeFunc,"in-modal":_vm.inModal,"refresh":_vm.updateTableFlag,"customer-params":_vm.params,"search-type-options":[
      { label: '名称', value: 'name' },
      { label: 'IP 地址', value: 'monitor_address' }
    ],"table-operation-width":_vm.tableOperationWidth,"tools-list":_vm.toolsList,"apiPermission":_vm.groupApiPermission},on:{"tableSelectData":_vm.getSelectData},scopedSlots:_vm._u([{key:"leftTool",fn:function(){return [_c('create-button',{directives:[{name:"permission",rawName:"v-permission",value:({action: 'base.storage.create', effect: 'disabled'}),expression:"{action: 'base.storage.create', effect: 'disabled'}"}],on:{"click":function($event){return _vm.$refs.inputDrawer.show()}}})]},proxy:true},{key:"name",fn:function({ slotProps }){return [_c('a-tooltip',{attrs:{"placement":"topLeft","title":slotProps.text}},[(_vm.link)?_c('a',{on:{"click":function($event){return _vm.$refs.detailDrawer.show(slotProps.record.id)}}},[_vm._v(" "+_vm._s(slotProps.text)+" ")]):_c('span',[_vm._v(_vm._s(slotProps.text))])])]}},{key:"snmpVersion",fn:function({ slotProps }){return [_vm._v(" "+_vm._s(_vm.$t(`snmp_version.${slotProps.text}`))+" ")]}},{key:"vendor",fn:function({ slotProps }){return [_vm._v(" "+_vm._s(_vm.$t(`hardware_vendor.${slotProps.text}`))+" ")]}},{key:"price",fn:function({ slotProps }){return [_vm._v(" "+_vm._s(slotProps.text ? `${slotProps.text} 元` : '-')+" ")]}},{key:"statusTitle",fn:function(){return [_c('status-question-icon')]},proxy:true},{key:"status",fn:function({ slotProps }){return [_c('source-status-tag',{attrs:{"status":slotProps.text}})]}},{key:"operation",fn:function({ slotProps }){return [_c('sync-button',{on:{"click":function($event){return _vm.sync(slotProps.record.id)}}}),_c('edit-button',{directives:[{name:"permission",rawName:"v-permission",value:({
          action: 'base.storage.update',
          effect: 'disabled'
        }),expression:"{\n          action: 'base.storage.update',\n          effect: 'disabled'\n        }"}],on:{"click":function($event){return _vm.$refs.inputDrawer.show(slotProps.record.id)}}}),_c('delete-button',{directives:[{name:"permission",rawName:"v-permission",value:({
          action: 'base.storage.delete',
          effect: 'disabled'
        }),expression:"{\n          action: 'base.storage.delete',\n          effect: 'disabled'\n        }"}],on:{"confirm":function($event){return _vm.confirm(slotProps.record.id)}}})]}}])}),_c('storage-drawer',{ref:"detailDrawer"}),_c('hardware-input-drawer',{ref:"inputDrawer",attrs:{"source-type":"storage"},on:{"ok":_vm.confirm}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }