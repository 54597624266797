<template>
  <a-table class="custom-table scroll-hidden-table" :columns="columns" :data-source="dataSource"
    :pagination="{ pageSize: 5 }" row-key="id" :components="components" :scroll="{ x: scrollX }" id="custom-table">
    <template slot="notificationMethod" slot-scope="text">
      <a-tag color="blue">{{ $t(`notification_method.${text}`) }}</a-tag>
    </template>
    <template slot="alert" slot-scope="text">
      <p style="white-space: pre-wrap;  padding-bottom: 8px">{{ text }}</p>
    </template>
    <template slot="success" slot-scope="text">
      <boolean-tag false-name="失败" true-name="成功" :value="text"></boolean-tag>
    </template>
    <template slot="message" slot-scope="text">
      <p style="white-space: pre-wrap;  padding-bottom: 8px">{{ text }}</p>
    </template>
  </a-table>
</template>

<script>
import BooleanTag from '@/components/tag/BooleanTag'

export default {
  name: 'NotificationTable',
  components: {
    BooleanTag
  },
  props: {
    dataSource: {
      type: Array
    }
  },
  data () {
    return {
      columns: [
        {
          dataIndex: 'notification_method',
          title: '通知方式',
          width: 100,
          scopedSlots: {
            customRender: 'notificationMethod'
          }
        },
        {
          dataIndex: 'to',
          title: '联系人',
          width: 200
        },
        {
          dataIndex: 'alert',
          title: '告警内容',
          width: 200,
          scopedSlots: {
            customRender: 'alert'
          }
        },
        {
          dataIndex: 'success',
          align: 'center',
          title: '状态',
          width: 100,
          scopedSlots: {
            customRender: 'success'
          }
        },
        {
          dataIndex: 'message',
          title: '详情',
          width: 200,
          scopedSlots: {
            customRender: 'message'
          }
        }
      ],
      components: {
        header: {}
      }
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  }
}
</script>
