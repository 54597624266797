<template>
  <a-drawer
    :destroy-on-close="true"
    :title="detail.id ? detail.name : '通知策略创建'"
    :visible="visible"
    :width="1000"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :wrapperCol="{ span: 20 }"
    >
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="严重性" prop="severities">
        <a-select
          v-model="form.severities"
          mode="multiple"
          :options="options.severity"
        ></a-select>
      </a-form-model-item>
      <a-form-model-item label="资源配置" prop="conditions">
        <div
          v-for="(condition, index) in form.conditions"
          :key="`condition_${index}`"
          style="display: flex"
        >
          <a-form-model-item
            :prop="`conditions[${index}].source_type`"
            :rules="[
              {
                message: '请选择资源类型',
                required: true,
                trigger: 'change'
              }
            ]"
            style="margin-right: 16px"
          >
            <a-select
              v-model="condition.source_type"
              :options="options.sourceType"
              style="width: 160px"
            ></a-select>
          </a-form-model-item>
          <a-form-model-item
            :prop="`conditions[${index}].source_target`"
            :rules="[
              {
                message: '请选择资源范围',
                required: true,
                trigger: 'change'
              }
            ]"
            style="width: 160px"
          >
            <a-select
              v-model="condition.source_target"
              :options="options.sourceTarget"
            ></a-select>
          </a-form-model-item>
          <template v-if="condition.source_type">
            <!-- 群组 -->
            <a-form-model-item
              v-if="condition.source_target === 'group'"
              :prop="`conditions[${index}].groups`"
              :rules="[
                {
                  message:
                    '请选择' +
                    $t(`source_type.${condition.source_type}`) +
                    '群组',
                  required: true,
                  trigger: 'change'
                }
              ]"
              style="margin-left: 16px"
            >
              <group-select
                v-model="condition.groups"
                :source-type="condition.source_type"
                style="width: 400px"
              ></group-select>
            </a-form-model-item>
            <!-- 资源 -->
            <a-form-model-item
              v-else-if="condition.source_target === 'selected'"
              :prop="`conditions[${index}].sources`"
              :rules="[
                {
                  message:
                    '请选择' + $t(`source_type.${condition.source_type}`),
                  required: true,
                  trigger: 'change'
                }
              ]"
              style="margin-left: 16px"
            >
              <multi-source-select
                v-model="condition.sources"
                :source-type="condition.source_type"
                style="width: 400px"
              ></multi-source-select>
            </a-form-model-item>
          </template>
          <a-form-model-item style="margin-left: 16px">
            <a-button
              icon="minus-circle"
              type="link"
              @click="form.conditions.splice(index, 1)"
            ></a-button>
          </a-form-model-item>
        </div>
        <a-button
          :block="true"
          icon="plus"
          type="dashed"
          @click="
            form.conditions.push({
              source_type: undefined,
              source_target: undefined,
              sources: undefined,
              groups: undefined
            })
          "
        >
          新增资源配置
        </a-button>
      </a-form-model-item>
      <a-form-model-item label="动作配置" prop="operations">
        <div
          v-for="(operation, index) in form.operations"
          :key="`operation_${index}`"
          style="display: flex"
        >
          <a-form-model-item
            :prop="`operations[${index}].notification_method`"
            :rules="[
              {
                message: '请选择通知方式',
                required: true,
                trigger: 'change'
              }
            ]"
            style="margin-right: 16px"
          >
            <a-select
              v-model="operation.notification_method"
              :options="options.notificationMethod"
              style="width: 160px"
            ></a-select>
          </a-form-model-item>
          <a-form-model-item :prop="`operations[${index}].delay`">
            <a-select
              v-model="operation.delay"
              :options="options.delay"
              style="width: 160px"
            ></a-select>
          </a-form-model-item>
          <a-form-model-item
            :prop="`operations[${index}].contacts`"
            :rules="[
              {
                message: '请选择联系人',
                required: true,
                trigger: 'change'
              }
            ]"
            style="margin-left: 16px"
          >
            <contact-select
              v-model="operation.contacts"
              style="width: 400px"
            ></contact-select>
          </a-form-model-item>
          <a-form-model-item style="margin-left: 16px">
            <a-button
              icon="minus-circle"
              type="link"
              @click="form.operations.splice(index, 1)"
            ></a-button>
          </a-form-model-item>
        </div>
        <a-button
          :block="true"
          icon="plus"
          type="dashed"
          @click="
            form.operations.push({
              notification_method: undefined,
              contacts: undefined
            })
          "
        >
          新增动作配置
        </a-button>
      </a-form-model-item>
      <a-form-model-item
        v-if="opsItsmApi"
        label="自动生成工单"
        prop="autoTicket"
      >
        <a-switch v-model="form.autoTicket"></a-switch>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="active">
        <a-switch v-model="form.active"></a-switch>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button v-if="detail.id" type="primary" v-permission="{action: 'base.alert_notification_strategy.update', effect: 'disabled'}" @click="ok">确定</a-button>
          <a-button v-else @click="ok">确定</a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getAlertNotificationStrategy,
  createAlertNotificationStrategy,
  updateAlertNotificationStrategy
} from '@/api/alert-notification-strategy'
import {
  alertSeverities,
  notificationMethods,
  sourceTargets,
  sourceTypes
} from '@/utils/const'
import ContactSelect from '@/components/select/ContactSelect'
import GroupSelect from '@/components/select/GroupSelect'
import MultiSourceSelect from '@/components/select/MultiSourceSelect'

export default {
  name: 'InputDrawer',
  components: {
    ContactSelect,
    GroupSelect,
    MultiSourceSelect
  },
  data () {
    return {
      visible: false,
      detail: {
        id: '',
        name: '',
        severities: [],
        created_at: '',
        updated_at: '',
        conditions: [],
        operations: [],
        autoTicket: false,
        active: false
      },
      form: {
        name: '',
        severities: undefined,
        conditions: [{}],
        operations: [{}],
        autoTicket: false,
        active: false
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        severities: [
          {
            message: '请选择严重性',
            required: true,
            trigger: 'change'
          }
        ],
        conditions: [
          {
            message: '请添加资源配置',
            required: true,
            trigger: 'change'
          }
        ],
        operations: [
          {
            message: '请添加动作配置',
            required: true,
            trigger: 'change'
          }
        ]
      },
      options: {
        severity: alertSeverities.map(value => {
          return {
            title: this.$t(`alert_severity.${value}`),
            value
          }
        }),
        sourceType: sourceTypes.map(value => {
          return {
            title: this.$t(`source_type.${value}`),
            value
          }
        }),
        sourceTarget: sourceTargets.map(value => {
          return {
            title: this.$t(`source_target.${value}`),
            value
          }
        }),
        delay: [
          { title: '立即', value: 0 },
          { title: '1 分钟后', value: 60 },
          { title: '5 分钟后', value: 300 },
          { title: '10 分钟后', value: 600 },
          { title: '15 分钟后', value: 900 },
          { title: '30 分钟后', value: 1800 },
          { title: '1 小时后', value: 3600 }
        ],
        notificationMethod: notificationMethods.map(value => {
          return {
            title: this.$t(`notification_method.${value}`),
            value
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters(['opsItsmApi'])
  },
  methods: {
    show (id) {
      if (id) {
        getAlertNotificationStrategy(id)
          .then(res => {
            this.detail = res.data
            this.form.name = this.detail.name
            this.form.severities = this.detail.severities
            this.form.conditions = this.detail.conditions
            this.form.operations = this.detail.operations
            if (this.opsItsmApi) this.form.autoTicket = this.detail.auto_ticket
            this.form.active = this.detail.active
          })
          .finally(() => {
            this.visible = true
          })
      } else {
        this.visible = true
      }
    },
    closeDrawer () {
      this.form = {
        name: '',
        severities: undefined,
        conditions: [{}],
        operations: [{}],
        autoTicket: false,
        active: false
      }
      this.detail.id = ''
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            name: this.form.name,
            severities: this.form.severities,
            active: this.form.active,
            conditions: this.form.conditions.map(condition => {
              const data = {
                source_type: condition.source_type,
                source_target: condition.source_target
              }
              if (condition.source_target === 'group') {
                data.group_ids = condition.groups.map(group => group.id)
              } else if (condition.source_target === 'selected') {
                data.source_ids = condition.sources.map(
                  source => source.key || source.id
                )
              }
              return data
            }),
            operations: this.form.operations.map(operation => {
              return {
                notification_method: operation.notification_method,
                delay: operation.delay,
                contact_ids: operation.contacts.map(
                  contact => contact.key || contact.id
                )
              }
            })
          }
          if (this.opsItsmApi) params.auto_ticket = this.form.autoTicket
          this.loading = true
          if (this.detail.id) {
            updateAlertNotificationStrategy(this.detail.id, params)
              .then(res => {
                this.$message.success(res.message)
              })
              .finally(() => {
                this.loading = false
                this.$emit('updated')
                this.closeDrawer()
              })
          } else {
            createAlertNotificationStrategy(params)
              .then(res => {
                this.$message.success(res.message)
              })
              .finally(() => {
                this.loading = false
                this.$emit('updated')
                this.closeDrawer()
              })
          }
        }
      })
    }
  }
}
</script>
