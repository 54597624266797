<template>
  <div>
    <list-layout
      :can-operate="canOperate"
      :column-default-selected="columnDefaultSelected"
      :columns-all="columnsAll"
      :default-selected="tableDefaultSelectData"
      :export-func="exportFunc"
      :get-table-data-func="getTableDataFunc"
      :group-tree-func="groupTreeFunc"
      :in-modal="inModal"
      :refresh="updateTableFlag"
      :customer-params="params"
      :search-type-options="[
        { label: '名称', value: 'name' },
        { label: 'IP 地址', value: 'monitor_address' }
      ]"
      :table-operation-width="tableOperationWidth"
      :tools-list="toolsList"
      @tableSelectData="getSelectData"
      :apiPermission="groupApiPermission"
    >
      <template #leftTool>
        <create-button v-permission="{action: 'base.server.create', effect: 'disabled'}" @click="$refs.inputDrawer.show()">
        </create-button>
      </template>
      <!-- 表格自定义列 -->
      <template #name="{ slotProps }">
        <a-tooltip placement="topLeft" :title="slotProps.text">
          <a @click="$refs.detailDrawer.show(slotProps.record.id)" v-if="link">
            {{ slotProps.text }}
          </a>
          <span v-else>{{ slotProps.text }}</span>
        </a-tooltip>
      </template>
      <template #snmpVersion="{ slotProps }">
        {{ $t(`snmp_version.${slotProps.text}`) }}
      </template>
      <template #vendor="{ slotProps }">
        {{ $t(`hardware_vendor.${slotProps.text}`) }}
      </template>
      <template #price="{ slotProps }">
        {{ slotProps.text ? `${slotProps.text} 元` : '-' }}
      </template>
      <template #statusTitle>
        <status-question-icon></status-question-icon>
      </template>
      <template #status="{ slotProps }">
        <source-status-tag :status="slotProps.text"></source-status-tag>
      </template>
      <template #operation="{ slotProps }">
        <sync-button @click="sync(slotProps.record.id)"></sync-button>
        <edit-button
         v-permission="{action: 'base.server.update', effect: 'disabled'}"
          @click="$refs.inputDrawer.show(slotProps.record.id)"
        ></edit-button>
        <delete-button  v-permission="{action: 'base.server.delete', effect: 'disabled'}"  @confirm="confirm(slotProps.record.id)"></delete-button>
      </template>
    </list-layout>

    <server-drawer ref="detailDrawer"></server-drawer>
    <hardware-input-drawer
      ref="inputDrawer"
      source-type="server"
      @ok="confirm"
    ></hardware-input-drawer>
  </div>
</template>

<script>
import {
  createServerGroup,
  deleteServer,
  deleteServerGroup,
  exportServer,
  getServerGroup,
  getServerGroupList,
  getServerList,
  syncServer,
  updateServerGroup
} from '@/api/server'
import ListLayout from '@/components/ListLayout'
import DeleteButton from '@/components/button/DeleteButton'
import CreateButton from '@/components/button/CreateButton'
import EditButton from '@/components/button/EditButton'
import SyncButton from '@/components/button/SyncButton'
import StatusQuestionIcon from '@/components/icon/StatusQuestionIcon'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
import { sourceStatuses } from '@/utils/const'
import { hasPermission } from '@/utils'

export default {
  name: 'ServerTable',
  components: {
    DeleteButton,
    EditButton,
    CreateButton,
    HardwareInputDrawer: () => import('@/components/drawer/HardwareInputDrawer'),
    ListLayout,
    ServerDrawer: () => import('@/components/drawer/ServerDrawer'),
    SourceStatusTag,
    StatusQuestionIcon,
    SyncButton
  },
  props: {
    defaultSelected: {
      type: Array
    },
    toolsList: {
      type: Array,
      default: () => []
    },
    tableOperationWidth: {
      type: Number,
      default: 240
    },
    link: {
      type: Boolean,
      default: true
    },
    refresh: {
      type: Boolean
    },
    canOperate: {
      type: Boolean,
      default: true
    },
    inModal: {
      type: Boolean,
      default: false
    },
    fetchParams: {
      type: Object
    }
  },
  data () {
    return {
      exportFunc: exportServer,
      getTableDataFunc: getServerList,
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'monitor_address',
          title: 'IP 地址',
          width: 160,
          scopedSlots: {
            customRender: 'monitorAddress'
          }
        },
        {
          dataIndex: 'monitor_port',
          title: '监控端口',
          width: 100,
          scopedSlots: {
            customRender: 'monitorPort'
          }
        },
        {
          dataIndex: 'snmp_version',
          title: 'SNMP 版本',
          width: 120,
          scopedSlots: {
            customRender: 'snmpVersion'
          }
        },
        {
          dataIndex: 'vendor',
          title: '制造商',
          width: 100,
          scopedSlots: {
            customRender: 'vendor'
          }
        },
        {
          dataIndex: 'model',
          title: '型号',
          width: 130,
          ellipsis: true,
          scopedSlots: {
            customRender: 'model'
          }
        },
        {
          dataIndex: 'firmware_version',
          title: '固件版本',
          width: 100,
          ellipsis: true,
          scopedSlots: {
            customRender: 'firmwareVersion'
          }
        },
        {
          dataIndex: 'serial_number',
          title: 'SN',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'serialNumber'
          }
        },
        {
          dataIndex: 'status',
          width: 100,
          slots: {
            title: 'statusTitle',
            name: '状态'
          },
          scopedSlots: {
            customRender: 'status'
          },
          filteredValue: [],
          filters: sourceStatuses.map(value => {
            return {
              text: this.$t(`source_status.${value}`),
              value
            }
          }),
          filterMultiple: false
        },
        {
          dataIndex: 'purchased_at',
          title: '采购日期',
          width: 120,
          scopedSlots: {
            customRender: 'purchasedAt'
          }
        },
        {
          dataIndex: 'expires_at',
          title: '过保日期',
          width: 120,
          scopedSlots: {
            customRender: 'expiresAt'
          }
        },
        {
          dataIndex: 'price',
          title: '采购金额',
          width: 120,
          scopedSlots: {
            customRender: 'price'
          }
        },
        {
          dataIndex: 'manager',
          title: '负责人',
          width: 120,
          scopedSlots: {
            customRender: 'manager'
          }
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        }
      ],
      columnDefaultSelected: [
        'name',
        'monitor_address',
        'vendor',
        'model',
        'status'
      ],
      updateTableFlag: this.refresh,
      customerParams: this.$route.params,
      tableSelectData: [],
      tableDefaultSelectData: [],
      groupApiPermission: {
        create: 'base.server_group.create',
        edit: 'base.server_group.update',
        delete: 'base.server_group.delete'
      }
    }
  },
  computed: {
    params () {
      const obj = {}
      for (const key in this.customerParams) {
        if (Object.hasOwnProperty.call(this.customerParams, key)) {
          obj[key] = this.customerParams[key]
        }
      }
      for (const key in this.fetchParams) {
        if (Object.hasOwnProperty.call(this.fetchParams, key)) {
          obj[key] = this.fetchParams[key]
        }
      }
      this.columnsAll.forEach(column => {
        if (Object.hasOwnProperty.call(column, 'filteredValue')) {
          if (Object.hasOwnProperty.call(obj, column.dataIndex)) {
            column.filteredValue = [obj[column.dataIndex]]
          } else column.filteredValue = []
        }
      })
      return obj
    },
    groupTreeFunc () {
      if (hasPermission(['base.server_group.view'])) {
        return {
          createFunc: createServerGroup,
          deleteFunc: deleteServerGroup,
          getFunc: getServerGroup,
          getListFunc: getServerGroupList,
          updateFunc: updateServerGroup
        }
      } else return null
    }
  },
  mounted () {
    if (this.defaultSelected) this.tableDefaultSelectData = this.defaultSelected
  },
  methods: {
    getSelectData (data) {
      this.tableSelectData = data
      this.$emit('selectedData', data)
    },
    async confirm (id = '') {
      if (id) {
        await deleteServer(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(this.tableSelectData.findIndex(item => item.key === id), 1)
        this.tableDefaultSelectData = this.tableSelectData
        this.$emit('selectedData', this.tableSelectData)
      }
      this.updateTableFlag = !this.updateTableFlag
    },
    sync (id) {
      syncServer({ id }).then(res => {
        this.$message.success(res.message)
      })
    }
  },
  watch: {
    refresh (v) {
      this.updateTableFlag = v
    }
  }
}
</script>
