import request from '@/utils/request'

const urlPrefix = '/contacts'

export function getContact (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateContact (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteContact (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getContactList (params) {
  return request.get(urlPrefix, { params })
}

export function createContact (data) {
  return request.post(urlPrefix, data)
}
