<template>
  <a-table
      :columns="columns"
      :data-source="snapshot.history"
      row-key="timestamp"
      :scroll="{
        x: scrollX
      }"
      size="small"
      style="height: 200px"
    >
      <template slot="timestamp" slot-scope="record">
        {{ record | humanTimestamp }}
      </template>
      <template slot="value" slot-scope="record">
        <a-tooltip placement="topLeft" :title="record">{{ record }}</a-tooltip>
      </template>
    </a-table>
</template>

<script>
import moment from 'moment'

export default {
  name: 'SnapshotTable',
  props: {
    snapshot: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      columns: [
        {
          dataIndex: 'timestamp',
          title: '时间',
          scopedSlots: {
            customRender: 'timestamp'
          }
        },
        {
          dataIndex: 'value',
          title: '值',
          ellipsis: true,
          scopedSlots: {
            customRender: 'value'
          }
        }
      ]
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  filters: {
    humanTimestamp (value) {
      return moment(value).format('YYYY-MM-DD HH:mm')
    }
  }
}
</script>
