<template>
  <div :id="chartId" style="height: 200px"></div>
</template>

<script>

import { Chart } from '@antv/g2'
import { humanizeValue, getIntegerTicks } from '@/utils'
let chart = null

export default {
  name: 'SnapshotChart',
  props: {
    chartId: {
      type: String,
      default: 'snapshot-chart'
    },
    snapshot: {
      type: Object,
      required: true
    }
  },
  mounted () {
    this.initChart()
  },
  methods: {
    initChart () {
      chart = new Chart({
        container: this.chartId,
        autoFit: true,
        appendPadding: [8, 8, 0, 0]
      })
      if (this.snapshot.value_maps && this.snapshot.value_maps.length) {
        const items = []
        this.snapshot.value_maps.forEach(item => {
          items.push({ name: `${item.key}: ${item.value}`, marker: { symbol: 'circle', style: { r: 0 } } })
        })
        chart.legend({
          custom: true,
          position: 'right',
          items
        })
      } else {
        chart.legend(false)
      }
      chart.scale({
        timestamp: {
          type: 'time',
          mask: 'MM-DD HH:mm'
        },
        value: {
          min: 0,
          nice: true,
          alias: '值',
          tickMethod: getIntegerTicks,
          formatter: value => humanizeValue(value)
        }
      })
      chart.tooltip({
        showCrosshairs: true
      })

      chart.axis('timestamp', {
        tickLine: null,
        line: null,
        grid: null,
        label: {
          style: {
            fill: '#808080'
          }
        }
      })

      chart.axis('value', {
        grid: {
          line: {
            style: {
              stroke: '#cccccc',
              strokeOpacity: 0.8,
              lineWidth: 1,
              lineDash: [4]
            }
          }
        },
        label: {
          style: {
            fill: '#808080'
          }
        }
      })

      chart.data(this.snapshot.history)
      chart.removeInteraction('legend-filter')
      chart.line().position('timestamp*value').color('#096DD9')
      chart.area().position('timestamp*value').color('l(90) 0:#096DD9 1:#DAE9F9')
      chart.render()
    }
  },
  beforeDestroy () {
    if (chart) {
      chart.clear()
      chart.destroy()
    }
  },

  watch: {
    snapshot: {
      handler (v) {
        if (v.value_maps && v.value_maps.length) {
          const items = []
          this.snapshot.value_maps.forEach(item => {
            items.push({ name: `${item.key}：${item.value}`, marker: { symbol: 'circle', style: { fill: '#096DD9', r: 0 } } })
          })
          chart.legend({
            custom: true,
            position: 'right',
            items
          })
        } else {
          chart.legend(false)
        }
        chart.changeData(v.history)
      }
    },
    deep: true
  }
}
</script>
