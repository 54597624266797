<template>
  <div>
    <list-layout
      :can-operate="canOperate"
      :column-default-selected="columnDefaultSelected"
      :columns-all="columnsAll"
      :default-selected="tableDefaultSelectData"
      :export-func="exportFunc"
      :get-table-data-func="getTableDataFunc"
      :group-tree-func="groupTreeFunc"
      :in-modal="inModal"
      :refresh="updateTableFlag"
      :search-type-options="[
        { label: '名称', value: 'name' },
        { label: 'IP 地址', value: 'monitor_address' }
      ]"
      :table-operation-width="tableOperationWidth"
      :tools-list="toolsList"
      @tableSelectData="getSelectData"
      :customer-params="params"
    >
      <template #leftTool>
        <create-button @click="$refs.inputDrawer.show()" v-permission="{action: 'base.hypervisor.create', effect: 'disabled'}">
        </create-button>
      </template>
      <!-- 表格自定义列 -->
      <template #name="{ slotProps }">
        <a-tooltip placement="topLeft" :title="slotProps.text">
          <a @click="$refs.detailDrawer.show(slotProps.record.id)" v-if="link">
            {{ slotProps.text }}
          </a>
          <span v-else>{{ slotProps.text }}</span>
        </a-tooltip>
      </template>
      <template #cpu="{ slotProps }">
        {{ slotProps.text ? `${slotProps.text} 核` : '-' }}
      </template>
      <template #memory="{ slotProps }">
        {{ slotProps.text ? `${slotProps.text} GB` : '-' }}
      </template>
      <template #statusTitle>
        <status-question-icon></status-question-icon>
      </template>
      <template #status="{ slotProps }">
        <source-status-tag :status="slotProps.text"></source-status-tag>
      </template>
      <template #operation="{ slotProps }">
        <sync-button @click="sync(slotProps.record.id)"></sync-button>
        <edit-button
          v-permission="{action: 'base.hypervisor.update', effect: 'disabled'}"
          @click="$refs.inputDrawer.show(slotProps.record.id)"
        ></edit-button>
        <delete-button v-permission="{action: 'base.hypervisor.delete', effect: 'disabled'}" @confirm="confirm(slotProps.record.id)"></delete-button>
      </template>
    </list-layout>

    <hypervisor-drawer ref="detailDrawer"></hypervisor-drawer>
    <input-drawer ref="inputDrawer" @ok="confirm"></input-drawer>
  </div>
</template>

<script>
import {
  createHypervisorGroup,
  deleteHypervisor,
  deleteHypervisorGroup,
  exportHypervisor,
  getHypervisorGroup,
  getHypervisorGroupList,
  getHypervisorList,
  syncHypervisor,
  updateHypervisorGroup
} from '@/api/hypervisor'
import ListLayout from '@/components/ListLayout'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import SyncButton from '@/components/button/SyncButton'
import StatusQuestionIcon from '@/components/icon/StatusQuestionIcon'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
import { sourceStatuses } from '@/utils/const'

export default {
  name: 'HypervisorTable',
  components: {
    DeleteButton,
    EditButton,
    InputDrawer: () => import('./modules/InputDrawer'),
    ListLayout,
    HypervisorDrawer: () => import('@/components/drawer/HypervisorDrawer'),
    StatusQuestionIcon,
    SourceStatusTag,
    SyncButton
  },
  props: {
    defaultSelected: {
      type: Array
    },
    toolsList: {
      type: Array,
      default: () => []
    },
    tableOperationWidth: {
      type: Number,
      default: 240
    },
    link: {
      type: Boolean,
      default: true
    },
    refresh: {
      type: Boolean
    },
    canOperate: {
      type: Boolean,
      default: true
    },
    inModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      getTableDataFunc: getHypervisorList,
      exportFunc: exportHypervisor,
      groupTreeFunc: {
        createFunc: createHypervisorGroup,
        deleteFunc: deleteHypervisorGroup,
        getFunc: getHypervisorGroup,
        getListFunc: getHypervisorGroupList,
        updateFunc: updateHypervisorGroup
      },
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'monitor_address',
          title: 'IP 地址',
          width: 160
        },
        {
          dataIndex: 'version',
          title: '版本',
          width: 160,
          ellipsis: true
        },
        {
          dataIndex: 'cpu',
          title: 'CPU',
          align: 'center',
          width: 100,
          scopedSlots: {
            customRender: 'cpu'
          }
        },
        {
          dataIndex: 'memory',
          title: '内存',
          align: 'center',
          width: 100,
          scopedSlots: {
            customRender: 'memory'
          }
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        },
        {
          dataIndex: 'status',
          align: 'center',
          width: 100,
          slots: {
            title: 'statusTitle',
            name: '状态'
          },
          scopedSlots: {
            customRender: 'status'
          },
          filteredValue: [],
          filters: sourceStatuses.map(value => {
            return {
              text: this.$t(`source_status.${value}`),
              value
            }
          }),
          filterMultiple: false
        }
      ],
      columnDefaultSelected: [
        'name',
        'version',
        'monitor_address',
        'cpu',
        'memory',
        'updated_at',
        'status'
      ],
      updateTableFlag: this.refresh,
      customerParams: this.$route.params,
      tableSelectData: [],
      tableDefaultSelectData: []
    }
  },
  computed: {
    params () {
      const obj = {}
      for (const key in this.customerParams) {
        if (Object.hasOwnProperty.call(this.customerParams, key)) {
          obj[key] = this.customerParams[key]
        }
      }
      return obj
    }
  },
  mounted () {
    if (this.defaultSelected) this.tableDefaultSelectData = this.defaultSelected
    const params = this.$route.params
    if (params.status) {
      this.columnsAll.filter(column => {
        if (column.dataIndex === 'status') {
          column.filteredValue = [params.status]
        }
      })
    }
  },
  methods: {
    getSelectData (data) {
      this.tableSelectData = data
      this.$emit('selectedData', data)
    },
    async confirm (id = '') {
      if (id) {
        await deleteHypervisor(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(this.tableSelectData.findIndex(item => item.key === id), 1)
        this.tableDefaultSelectData = this.tableSelectData
        this.$emit('selectedData', this.tableSelectData)
      }
      this.updateTableFlag = !this.updateTableFlag
    },
    sync (id) {
      syncHypervisor({ id }).then(res => {
        this.$message.success(res.message)
      })
    }
  },
  watch: {
    refresh (v) {
      this.updateTableFlag = v
    }
  }
}
</script>
