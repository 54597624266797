<template>
  <a-modal
    v-model="visible"
    :after-close="afterClose"
    :destroy-on-close="true"
    :footer="null"
    :title="name"
    :width="1080"
  >
    <a-space size="large" style="margin-bottom: 24px">
      <datetime-range
        :allow-clear="false"
        :default-value="defaultValue"
        @ok="
          v => {
            datetimeRange = v
          }
        "
      ></datetime-range>
      <a-checkbox v-model="compareYesterday">
        <question-icon title="环比" description="昨天同时段"></question-icon>
      </a-checkbox>
      <a-checkbox v-model="compareLastWeek">
        <question-icon title="同比" description="上周同时段"></question-icon>
      </a-checkbox>
    </a-space>

    <multi-line-chart
      :compare-last-week="compareLastWeek"
      :compare-yesterday="compareYesterday"
      :datetime-range="datetimeRange"
      :history-func="historyFunc"
      :name="name"
      :in-key="inKey"
      :out-key="outKey"
      :id="sourceId"
    ></multi-line-chart>
  </a-modal>
</template>

<script>
import { getNetworkDeviceMonitorHistory } from '@/api/network-device'
import { getOSMonitorHistory } from '@/api/os'
import { getServerMonitorHistory } from '@/api/server'
import { getStorageMonitorHistory } from '@/api/storage'
import DatetimeRange from '@/components/DatetimeRange'
import QuestionIcon from '@/components/icon/QuestionIcon'
import moment from 'moment/moment'
import MultiLineChart from './modules/MultiLineChart'

export default {
  name: 'NetworkInterfaceChartModal',
  components: {
    DatetimeRange,
    MultiLineChart,
    QuestionIcon
  },
  props: {
    defaultValue: {
      type: Array,
      default: () => [moment().subtract(1, 'hour'), moment()]
    },
    sourceType: {
      type: String
    },
    sourceId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      visible: false,
      datetimeRange: this.defaultValue || [],
      compareYesterday: false,
      compareLastWeek: false,
      inKey: '',
      outKey: '',
      name: ''
    }
  },
  computed: {
    historyFunc () {
      if (this.sourceType === 'os') return getOSMonitorHistory
      if (this.sourceType === 'server') return getServerMonitorHistory
      if (this.sourceType === 'storage') return getStorageMonitorHistory
      else return getNetworkDeviceMonitorHistory
    }
  },
  methods: {
    show (data) {
      this.name = data.name
      if (this.sourceType === 'os') {
        this.inKey = `"${data.name}"`
        this.outKey = `"${data.name}"`
      } else {
        this.inKey = `ifHCInOctets.${data.index}`
        this.outKey = `ifHCOutOctets.${data.index}`
      }
      this.visible = true
    },
    afterClose () {
      this.compareYesterday = false
      this.compareLastWeek = false
    }
  }
}
</script>
